import React from 'react';
import PropTypes from 'prop-types';
import { Form as BForm } from 'reactstrap';
import ObjectInput from './ObjectInput';

const { Provider, Consumer } = React.createContext();

export function Input(props) {
	return <Consumer>
		{({ parent, object }) => <ObjectInput parent={parent} object={object} {...props} />}
	</Consumer>;
}

export class InputGroup extends React.Component {
	static get propTypes() {
		return {
			parent: PropTypes.object.isRequired,
			object: PropTypes.string.isRequired,
		};
	}

	constructor(props) {
		super(props);
		this.state = { value: this.getValue(props) };
	}

	getValue({ parent, object }) {
		return { parent, object };
	}

	componentWillReceiveProps(props) {
		if(this.props.parent !== props.parent || this.props.object !== props.object) {
			this.setState({ value: this.getValue(props) });
		}
	}

	render() {
		return <Provider value={this.state.value}>
			{this.props.children}
		</Provider>;
	}
}

export class Form extends InputGroup {
	render() {
		const props = Object.assign({}, this.props);
		delete props.parent;
		delete props.object;
		return <Provider value={this.state.value}>
			<BForm {...props} />
		</Provider>;
	}
}
