import React, { Fragment } from 'react';
import { Button, Table, Input, Form, FormGroup, InputGroup, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { getItems, updateLocations, updateItem, instantiateItem, deleteItems, getReasons } from '../../api';
import dispatchMessage from '../../reducers/actions/message';

class Item extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lines: this.getState(props),
			price: props.item.prijzen.verkoop,
			quantity: 1,
			modal: false,
			deleteLine: null,
			deleteQuantity: 0,
			deleteReason: null,
			reasons: null,
		};
		this.submit = this.submit.bind(this);
		this.createInstances = this.createInstances.bind(this);
		this.toggleDelete = this.toggleDelete.bind(this);
		this.deleteItems = this.deleteItems.bind(this);
	}

	componentDidMount() {
		if(!this.state.reasons) {
			getReasons().then(reasons => {
				this.setState({ reasons });
			}).catch(err => {
				dispatchMessage('Failed to get reasons', err);
			});
		}
	}

	componentDidUpdate(props) {
		if(this.props.item !== props.item) {
			this.setState({
				lines: this.getState(this.props),
				price: props.item.prijzen.verkoop,
			});
		}
	}

	getState(props) {
		return props.item.quantity.map(({ batch, locatie, quantity }) =>
			({ batch, locatie, quantity, original: { locatie, quantity } }));
	}

	update(key, i, map = v => v) {
		return e => {
			const { lines } = this.state;
			this.setState({
				lines: lines.slice(0, i).concat(Object.assign({}, lines[i], {
					[key]: map(e.target.value),
				})).concat(lines.slice(i + 1)),
			});
		};
	}

	split(i) {
		return e => {
			e.preventDefault();
			const { lines } = this.state;
			const line = lines[i];
			this.setState({
				lines: lines.slice(0, i).concat([
					Object.assign({}, line, {
						quantity: line.quantity - 1,
					}),
					Object.assign({}, line, {
						quantity: 1,
					}),
				]).concat(lines.slice(i + 1)),
			});
		};
	}

	submit(e) {
		e.preventDefault();
		const { lines } = this.state;
		const updates = lines.filter(line => line.quantity > 0
            && (line.original.quantity !== line.quantity || line.original.locatie !== line.locatie)).map(line => {
			return {
				batch: line.batch,
				from: line.original.locatie,
				to: line.locatie,
				quantity: line.quantity,
			};
		});
		updateLocations(this.props.item.artikelNummer, updates).catch(err => {
			dispatchMessage('Failed to update locations', err);
		}).then(() => {
			this.props.updateItems();
		});
	}

	createInstances(e) {
		e.preventDefault();
		const { quantity } = this.state;
		instantiateItem(this.props.item._id, quantity).then(() => {
			this.setState({ quantity: 1 });
			this.props.updateItems();
		}).catch(err => {
			dispatchMessage('Failed to create instances', err);
		});
	}

	toggleDelete() {
		this.setState({ modal: !this.state.modal });
	}

	openDelete(line) {
		return e => {
			e.preventDefault();
			this.setState({ modal: true, deleteLine: line, deleteQuantity: 0, deleteReason: null });
		};
	}

	deleteItems() {
		const { item } = this.props;
		const { deleteLine, deleteQuantity, deleteReason } = this.state;
		deleteItems(item.artikelNummer, deleteReason, deleteLine.locatie, +deleteQuantity, deleteLine.batch).then(() => {
			this.props.updateItems();
			this.setState({ modal: false, deleteLine: null, deleteQuantity: 0, deleteReason: null });
		}).catch(err => {
			dispatchMessage('Failed to delete items', err);
		});
	}

	render() {
		const { item, user } = this.props;
		const { lines, price, quantity, modal, deleteLine, deleteQuantity, reasons, deleteReason } = this.state;
		const max = {};
		lines.forEach(line => {
			const key = `${line.batch}_${line.original.locatie}`;
			if(!max[key]) {
				max[key] = 0;
			}
			max[key] += line.quantity;
		});
		return <section className="container">
			<h1>{item.artikelNummer}</h1>
			{deleteLine !== null && <Modal isOpen={modal} toggle={this.toggleDelete}>
				<ModalHeader toggle={this.toggleDelete}>{deleteLine.batch} {deleteLine.locatie}</ModalHeader>
				<ModalBody>
					<FormGroup>
						<Input type="select" onChange={e => this.setState({ deleteReason: e.target.value })} value={deleteReason}>
							<option value={null}>Selecteer een reden</option>
							{reasons.map(reason => <option key={reason.id} value={reason.id}>{reason.description}</option>)}
						</Input>
					</FormGroup>
					<FormGroup>
						<Input
							type="number"
							min={1}
							max={deleteLine.quantity}
							value={deleteQuantity}
							onChange={e => this.setState({ deleteQuantity: e.target.value })} />
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button
						color="primary"
						onClick={this.deleteItems}
						disabled={deleteQuantity < 1 || deleteQuantity > deleteLine.quantity || !deleteReason}>
                        Boek af
					</Button>
					<Button color="secondary" onClick={this.toggleDelete}>Cancel</Button>
				</ModalFooter>
			</Modal>}
			<Row>
				<Col md={6}>
					<p>
						<img className="item-image" src={`https://api.ptmd.nl/rest/images/v1/small/${item.artikelNummer}.jpg`} />
					</p>
				</Col>
				<Col md={6}>
					{user.roles.some(r => r === 'PURCHASE' || r === 'STOCK') && <Form className="form-inline" onSubmit={this.createInstances}>
						<FormGroup>
							<Input
								type="number"
								min={1}
								value={quantity}
								onChange={e => this.setState({ quantity: e.target.value })} />
						</FormGroup>
						<Input
							type="submit"
							className="btn btn-primary"
							value="Maak aan" />
					</Form>}
				</Col>
			</Row>
			{user.roles.includes('SALES') && <Fragment>
				<h2>Verkoopprijs</h2>
				<Form onSubmit={e => {
					e.preventDefault();
					updateItem(item.artikelNummer, { price }).catch(err => {
						dispatchMessage('Failed to update item', err);
					});
				}}>
					<InputGroup>
						<Input step={0.01} type="number" value={price} onChange={e => {
							const f = parseFloat(e.target.value);
							this.setState({ price: f || e.target.value });
						}} />
					</InputGroup>
					<input type="submit" className="ptmd-button green-button" value="Save" disabled={isNaN(price) || item.prijzen.verkoop === price} />
				</Form>
			</Fragment>}
			<h2>Locaties</h2>
			<Form onSubmit={this.submit}>
				<Table>
					<thead>
						<tr>
							<th>Batch</th>
							<th colSpan={2}>Locatie</th>
							<th>Hoeveelheid</th>
							<th>Afboeken</th>
						</tr>
					</thead>
					<tbody>
						{lines.map((line, i) => <tr key={`${i}`}>
							<td>{line.batch}</td>
							<td>
								<Input value={line.locatie} onChange={this.update('locatie', i)} />
							</td>
							<td>
								<button
									className="ptmd-button no-margin"
									onClick={this.split(i)}
									disabled={line.quantity <= 1}>
                                    Split
								</button>
							</td>
							<td>
								<Input
									value={line.quantity}
									type="number"
									min={0}
									max={line.original.quantity - max[`${line.batch}_${line.original.locatie}`] + line.quantity}
									onChange={this.update('quantity', i, Number.parseInt)} />
							</td>
							<td>
								<button
									className="ptmd-button no-margin red-button"
									onClick={this.openDelete(line)}
									disabled={line.quantity < 1 || !reasons}>
                                    &times;
								</button>
							</td>
						</tr>)}
					</tbody>
				</Table>
				<input type="submit" className="ptmd-button green-button" value="Save" />
			</Form>
		</section>;
	}
}

export default class Page extends React.Component {
	constructor(props) {
		super(props);
		this.state = { items: [] };
		this.updateItems = this.updateItems.bind(this);
	}

	componentDidMount() {
		this.updateItems();
	}

	updateItems() {
		getItems().then(items => {
			this.setState({ items });
		}).catch(err => {
			dispatchMessage('Failed to get items', err);
		});
	}

	render() {
		const { items } = this.state;
		const { match, user } = this.props;
		const item = items.find(({ artikelNummer }) => artikelNummer === match.params.id);
		if(!item) {
			return null;
		}
		return <Item item={item} user={user} updateItems={this.updateItems} />;
	}
}
