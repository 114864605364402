import React from 'react';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import Pagination from '../../components/Pagination';
import { getItems } from '../../api';
import dispatchMessage from '../../reducers/actions/message';

export default class Items extends React.Component {
	constructor(props) {
		super(props);
		this.state = { items: [] };
	}

	componentDidMount() {
		getItems().then(items => {
			this.setState({ items });
		}).catch(err => {
			dispatchMessage('Failed to get items', err);
		});
	}

	render() {
		const { items } = this.state;
		const { filter, i18n, user } = this.props;
		const canEdit = user.roles.some(role => role === 'SALES' || role === 'STOCK');
		return <Pagination
			data={items}
			perPage={10}
			filter={filter}
			component={page =>
				<Table>
					<thead>
						<tr><th colSpan={2}>Code</th><th>Naam</th><th>Voorraad</th></tr>
					</thead>
					<tbody>
						{page.map(item =>
							<tr key={item.artikelNummer}>
								<td><img className="item-image" src={`https://api.ptmd.nl/rest/images/v1/small/${item.artikelNummer}.jpg`} /></td>
								<td>{
									canEdit ? <Link to={`/staff/items/${item.artikelNummer}`}>{item.artikelNummer}</Link> : item.artikelNummer
								}</td>
								<td>{i18n.select(item.naam)}</td>
								<td>{item.quantity.reduce((all, { quantity }) => all + quantity, 0)}</td>
							</tr>
						)}
					</tbody>
				</Table>
			} />;
	}
}
