import {
	WEBSHOP_SET_SELECTED_TAB,
	WEBSHOP_UPDATE_CATEGORIES,
	WEBSHOP_UPDATE_ITEMS,
	WEBSHOP_UPDATE_PRODUCT_GROUPS,
	WEBSHOP_ADD_TO_CART,
	WEBSHOP_SET_CART,
	WEBSHOP_CLEAR_CART,
	WEBSHOP_UPDATE_ORDERS,
	WEBSHOP_SET_CUSTOMER,
	WEBSHOP_SET_CART_SHIPPING,
	WEBSHOP_SET_CART_ADDRESS,
	WEBSHOP_SET_VAT,
	WEBSHOP_SET_DELIVERY_WEEKS,
	WEBSHOP_SET_TYPE,
	WEBSHOP_SET_SURCHARGE,
} from '../action-types/webshop';

export function updateCategories(categories) {
	return {
		type: WEBSHOP_UPDATE_CATEGORIES,
		payload: {
			categories,
		},
	};
}

export function setSelectedTab(tab) {
	return {
		type: WEBSHOP_SET_SELECTED_TAB,
		payload: {
			tab,
		},
	};
}

export function updateItems(items) {
	return {
		type: WEBSHOP_UPDATE_ITEMS,
		payload: {
			items,
		},
	};
}

export function updateProductGroups(productGroups) {
	return {
		type: WEBSHOP_UPDATE_PRODUCT_GROUPS,
		payload: {
			productGroups,
		},
	};
}

export function addToCart(item, batches, locatie, deliver = true) {
	return {
		type: WEBSHOP_ADD_TO_CART,
		payload: {
			item,
			batches,
			discount: 0,
			locatie,
			deliver,
		},
	};
}

export function setCart({ item, batches, locatie }, quantity, discount, deliver) {
	return {
		type: WEBSHOP_SET_CART,
		payload: {
			item,
			batches,
			quantity,
			discount,
			locatie,
			deliver,
		},
	};
}

export function clearCart() {
	return {
		type: WEBSHOP_CLEAR_CART,
		payload: {},
	};
}

export function updateOrders(orders) {
	return {
		type: WEBSHOP_UPDATE_ORDERS,
		payload: {
			orders,
		},
	};
}

export function setCustomer(customer) {
	return {
		type: WEBSHOP_SET_CUSTOMER,
		payload: customer,
	};
}

export function setCartShipping(shipping) {
	return {
		type: WEBSHOP_SET_CART_SHIPPING,
		payload: shipping,
	};
}

export function setCartAddress(address) {
	return {
		type: WEBSHOP_SET_CART_ADDRESS,
		payload: address,
	};
}

export function setVAT(vat) {
	return {
		type: WEBSHOP_SET_VAT,
		payload: vat,
	};
}

export function setDeliveryWeeks(weeks) {
	return {
		type: WEBSHOP_SET_DELIVERY_WEEKS,
		payload: weeks,
	};
}

export function setType(type) {
	return {
		type: WEBSHOP_SET_TYPE,
		payload: type,
	};
}

export function setSurcharge(surcharge) {
	return {
		type: WEBSHOP_SET_SURCHARGE,
		payload: surcharge,
	};
}
