import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

export default class RouteWithProps extends React.Component {
	static get propTypes() {
		return {
			component: PropTypes.func.isRequired,
			path: PropTypes.string.isRequired,
			exact: PropTypes.bool,
		};
	}

	render() {
		const { path, component: Component, exact } = this.props;
		const props = Object.assign({}, this.props);
		delete props.path;
		delete props.component;
		delete props.exact;
		return <Route exact={exact} path={path} render={p => <Component {...p} {...props} />} />;
	}
}
