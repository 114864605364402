import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Auth from './components/Auth';
import TopBar from './components/top/TopBar';
import { checkLoggedIn } from './api';
import I18n from './i18n';
import LoggedInRoutes from './routes/LoggedInRoutes';
import ErrorContainer from './containers/ErrorContainer';
import { loadFromLocalStorage, saveToLocalStorage } from './util/storage';

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			login: null,
			i18n: this.createI18n(),
		};
		this.setLanguage = this.setLanguage.bind(this);
	}

	componentDidMount() {
		checkLoggedIn().then((login = null) => {
			this.setState({ login });
		});
	}

	componentDidUpdate(props) {
		const { user } = this.props;
		if(user !== props.user) {
			const i18n = this.createI18n(user);
			this.setState({ i18n });
			if(!user) {
				checkLoggedIn().then((login = null) => {
					this.setState({ login });
				});
			}
		}
	}

	createI18n(user, code = loadFromLocalStorage('language', false, undefined)) {
		return new I18n(this.setLanguage, user, code);
	}

	setLanguage(code) {
		const { user } = this.props;
		const i18n = this.createI18n(user, code);
		this.setState({ i18n });
		saveToLocalStorage('language', code);
	}

	render() {
		const { login, i18n } = this.state;
		const { user } = this.props;
		if(!login && !user) {
			return false;
		}
		return <div>
			<TopBar i18n={i18n} />
			<main>
				{user ? <LoggedInRoutes i18n={i18n} user={user} />
					: <Auth user={user} login={login} />}
			</main>
			<ErrorContainer />
		</div>;
	}
}

export default withRouter(connect(store => {
	const { user } = store.auth;
	return { user };
})(App));
