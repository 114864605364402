import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'reactstrap';
import { Link } from 'react-router-dom';
import Cart from '../webshop/Cart';

export default class CartDropdown extends React.Component {
	static get propTypes() {
		return {
			cart: PropTypes.array.isRequired,
		};
	}

	render() {
		const { cart, i18n, user, getPriceAndCbm, type, setType } = this.props;
		if(!cart.length) {
			return null;
		}
		return (
			<div className="big-dropdown cart-dropdown">
				<Cart
					cart={cart}
					i18n={i18n}
					user={user}
					type={type}
					setType={setType}
					getPriceAndCbm={getPriceAndCbm}
				>
					<Row>
						<Col xs={12}>
							<div className="cart-container">
								<Row>
									<Col xs={12} lg={{size: 4, offset: 8}}>
										<Link to="/checkout" className="ptmd-button green-button">
                                            Volgende
										</Link>
									</Col>
								</Row>
							</div>
						</Col>
					</Row>
				</Cart>
			</div>
		);
	}
}
