import React, { Fragment } from 'react';
import { Table, Row, Col } from 'reactstrap';
import queryString from 'query-string';
import { getUnsentOrders } from '../../api';
import dispatchMessage from '../../reducers/actions/message';
import Address from '../../components/orders/Address';

class UitleverList extends React.Component {
	constructor(props) {
		super(props);
		this.state = { stickers: false };
	}

	getPickList() {
		const { orders, i18n } = this.props;
		return orders.map(order => {
			return <Fragment key={order._id}>
				<h1>{order._id}</h1>
				<Address adres={order.adres} />
				<Table>
					<thead>
						<tr>
							<th>Item</th>
							<th>Naam</th>
							<th>Hoeveelheid</th>
							<th>Bezorgen</th>
							<th>Locaties</th>
						</tr>
					</thead>
					<tbody>
						{order.regels.map((line, i) => <tr key={i}>
							<td>
								<img src={`https://api.ptmd.nl/rest/images/v1/small/${line.code}.jpg`} className="item-image" /><br />
								{line.code}
							</td>
							<td>{i18n.select(line.items[0].naam)}</td>
							<td>{line.quantity}</td>
							<td><input type="checkbox" disabled checked={line.deliver} /></td>
							<td>{line.locaties && line.locaties.join(', ')}</td>
						</tr>)}
					</tbody>
				</Table>
			</Fragment>;
		});
	}

	getStickers() {
		const perPage = 4;
		return this.props.orders.map(order => {
			const stickers = [];
			if(order.regels.length) {
				for(let n = 0; n < perPage; n++) {
					stickers.push(<div key={n} className="sticker">
						<h3>{order._id}</h3>
						<Address adres={order.adres} />
					</div>);
				}
			}
			return <Fragment key={order._id}>{stickers}</Fragment>;
		});
	}

	render() {
		const { stickers } = this.state;
		return <section className={stickers ? 'stickers' : 'container'}>
			<Row className="no-print">
				<Col md={8}>
					<button className="ptmd-button" onClick={() => {
						window.print();
					}}>Print</button>
				</Col>
				<Col md={4}>
					<button className={`ptmd-button ${stickers ? 'green-button' : ''}`} onClick={() => {
						this.setState({ stickers: !stickers });
					}}>Stickers</button>
				</Col>
			</Row>
			{stickers ? this.getStickers() : this.getPickList()}
		</section>;
	}
}

export default class Page extends React.Component {
	constructor(props) {
		super(props);
		this.state = { orders: null };
	}

	componentDidMount() {
		const { search } = this.props.location;
		const { ids } = queryString.parse(search);
		getUnsentOrders(ids).then(orders => {
			this.setState({ orders });
		}).catch(err => {
			dispatchMessage('Failed to get orders', err);
		});
	}

	render() {
		const { orders } = this.state;
		if(!orders) {
			return null;
		}
		return <UitleverList orders={orders} i18n={this.props.i18n} />;
	}
}
