import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'reactstrap';
import moment from 'moment';
import { addToCart, setCart } from '../../reducers/actions/webshop';
import { getInCart } from '../../reducers/webshop-reducer';
import FontAwesome from '../FontAwesome';
import store from '../../store';
import { getSize, getVolume } from './Webshop';

export default class SingleItem extends React.Component {
	static get propTypes() {
		return {
			item: PropTypes.object.isRequired,
			productGroup: PropTypes.object.isRequired,
		};
	}

	addToCart(batches) {
		return () => {
			store.dispatch(addToCart(this.props.item, batches));
		};
	}

	removeFromCart(batches, total) {
		return () => {
			const { item } = this.props;
			store.dispatch(setCart({
				item,
				batches,
			}, total - 1));
		};
	}

	render() {
		const { item, productGroup, i18n, cart, user } = this.props;
		const meta = {
			Code: item.artikelNummer,
			Grootte: getSize(item.afmetingen.item),
			Volume: <Fragment>{getVolume(item.afmetingen)} m<sup>3</sup></Fragment>,
			Productgroep: i18n.select(productGroup),
		};

		const header = <h3 className="product-title">
			{i18n.select(item.naam)}<br />
			<span>{i18n.select(productGroup)}</span>
		</h3>;

		const price = <h4 className="text-center text-lg-left">{i18n.format(item.price)}</h4>;
		const canOrder = user.roles.includes('CUSTOMER') || user.roles.includes('SALESPERSON');

		const locationKeys = {};
		const batches = [];
		const now = moment();
		const arrived = [];
		item.quantity.sort((lhs, rhs) => {
			if(lhs.eta > rhs.eta) {
				return 1;
			} else if(lhs.eta < rhs.eta) {
				return -1;
			}
			return 0;
		}).forEach(quantity => {
			quantity.locations.forEach(location => {
				if(location) {
					locationKeys[location] = true;
				}
			});
			const batch = Object.assign({}, quantity, { eta: moment(quantity.eta) });
			if(batch.eta.isBefore(now)) {
				if(!arrived.length) {
					batches.push(arrived);
				}
				arrived.push(batch);
			} else {
				batches.push([batch]);
			}
		});
		const locations = Object.keys(locationKeys).sort();

		return <div className="single-item">
			<div className="d-block d-lg-none">
				{header}
			</div>

			<Row>
				<Col xs={12} lg={7}>
					<Row>
						<Col className="product-meta-column" xs={12} lg={6}>
							<div className="d-flex">
								<div className="outer-product-meta d-none d-lg-flex">
									<table className="product-meta">
										<tbody>
											{Object.keys(meta).map(k => {
												return (
													<tr key={k}>
														<th>{k}</th>
														<td>{meta[k]}</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>
							</div>
						</Col>

						<Col xs={12} lg={6}>
							<img className="product-image" src={`https://api.ptmd.nl/rest/images/v1/bigger/${item.artikelNummer}.jpg`} />

							<div className="d-block d-lg-none">
								{price}
							</div>
						</Col>
					</Row>
				</Col>

				<Col xs={12} lg={5}>
					<div className="d-none d-lg-block">
						{header}
						<span className="d-block mt-4">{price}</span>
					</div>

					<div className="cart-section">
						{batches.map(quantities => {
							const quantity = quantities[0];
							const totalQuantity = quantities.reduce((sum, current) => sum + current.quantity, 0);
							const availability = quantity.eta.isBefore(now) ? `${totalQuantity} beschikbaar` : `${totalQuantity} beschikbaar ${quantity.eta.fromNow()}`;
							const batchIds = quantities.map(({ batch }) => batch);
							const inCart = getInCart(cart, { item, batches: batchIds });
							return <Fragment key={quantity.batch}>
								<div className="flex-center">
									<div>
										{availability} {locations.length ? `(${locations.join(', ')})` : null}
										{inCart > 0 && <div>
											{inCart} / {totalQuantity} in mandje
										</div>}
									</div>
								</div>
								{canOrder && <Row>
									<Col lg={4} md={6}>
										<button className="ptmd-button red-button add-to-cart"
											onClick={this.removeFromCart(batchIds, inCart)}
											disabled={inCart <= 0}>
                                            Verwijder
										</button>
									</Col>
									<Col lg={8} md={6}>
										<button className="ptmd-button green-button add-to-cart"
											onClick={this.addToCart(batchIds)}
											disabled={inCart >= totalQuantity}>
											<FontAwesome icon="shopping-cart" /> Voeg toe
										</button>
									</Col>
								</Row>}
							</Fragment>;
						})}
					</div>
				</Col>
			</Row>
		</div>;
	}
}
