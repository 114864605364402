import { getLocales, getLanguage, isLanguage, DEFAULT_LANGUAGE } from './util/locale';
import moment from 'moment';
const DEFAULT_CURRENCY = 'EUR';

export default class I18n {
	constructor(setLanguage, user, language) {
		const currency = user && user.valuta || DEFAULT_CURRENCY;
		if(!isLanguage(language)) {
			language = undefined;
		}
		if(!language && user && user.taal) {
			language = user.taal;
		}
		const locales = getLocales(language);
		moment.locale(locales);
		this.numberFormat = new Intl.NumberFormat(locales, {
			style: 'currency', currency,
		});
		this.language = language || getLanguage(locales);
		this.setLanguage = setLanguage;
	}

	format(...args) {
		return this.numberFormat.format(...args);
	}

	select(translations) {
		if(!translations) {
			return '';
		} else if(translations.name && translations.names) {
			return translations.names[this.language] || translations.name;
		}
		const translation = translations[this.language];
		if(translation) {
			return translation;
		} else if(translations[DEFAULT_LANGUAGE]) {
			return translations[DEFAULT_LANGUAGE];
		}
		return translations[Object.keys(translations)[0]];
	}
}
