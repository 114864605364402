import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Row, Col, InputGroup, InputGroupAddon } from 'reactstrap';
import FontAwesome from './FontAwesome';

export default class Pagination extends React.Component {
	static get propTypes() {
		return {
			data: PropTypes.array.isRequired,
			perPage: PropTypes.number.isRequired,
			component: PropTypes.func.isRequired,
			filter: PropTypes.func,
		};
	}

	constructor(props) {
		super(props);
		this.state = {
			page: 1,
			query: '',
			data: props.data || [],
		};
		this.prevPage = this.prevPage.bind(this);
		this.nextPage = this.nextPage.bind(this);
		this.filter = this.filter.bind(this);
		this.section = React.createRef();
	}

	componentWillReceiveProps(props) {
		const { data } = props;
		if(this.props.data !== data) {
			this.setState({ data: data || [] });
		}
	}

	scrollToTop() {
		let top = 0;
		if(this.section.current && this.section.current.offsetTop) {
			top = this.section.current.offsetTop;
		}
		document.documentElement.scrollTop = top;
	}

	prevPage() {
		this.setState({ page: this.state.page - 1 });
		this.scrollToTop();
	}

	nextPage() {
		this.setState({ page: this.state.page + 1 });
		this.scrollToTop();
	}

	renderPagination() {
		const { perPage } = this.props;
		const { page, data } = this.state;
		const pages = Math.ceil(data.length / perPage);
		return <p className="text-center">
			<button onClick={this.prevPage} disabled={page <= 1} className="btn btn-sm"><FontAwesome icon="chevron-circle-left" /></button>
			<span> {page} / {pages} </span>
			<button onClick={this.nextPage} disabled={page >= pages} className="btn btn-sm"><FontAwesome icon="chevron-circle-right" /></button>
		</p>;
	}

	filter(e) {
		e.preventDefault();
		const { data, filter } = this.props;
		let { query } = this.state;
		query = query.trim().toLowerCase();
		if(!query) {
			return this.setState({ data, page: 1 });
		}
		this.setState({
			data: data.filter(item => filter(query, item)),
			page: 1,
		});
	}

	render() {
		const { page, query, data } = this.state;
		const { perPage, component, filter } = this.props;
		return <section ref={this.section}>
			{filter ? <Row>
				<Col md={4}>
					<Form className="form-inline" onSubmit={this.filter}>
						<InputGroup>
							<Input type="search" placeholder="Zoek" value={query} onChange={e => this.setState({ query: e.target.value })} />
							<InputGroupAddon addonType="append">
								<button className="btn btn-primary">
									<FontAwesome icon="search" />
								</button>
							</InputGroupAddon>
						</InputGroup>
					</Form>
				</Col>
				<Col md={4}>
					{this.renderPagination()}
				</Col>
			</Row> : this.renderPagination()}
			{component(data.slice((page - 1) * perPage, page * perPage))}
			{this.renderPagination()}
		</section>;
	}
}
