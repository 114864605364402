import React from 'react';
import {connect} from 'react-redux';
import { fetchItems, fetchProductGroups } from '../api';
import Webshop from '../components/webshop/Webshop';

class WebshopContainer extends React.Component {
	componentDidMount() {
		fetchProductGroups();
		fetchItems();
	}

	render() {
		const { productGroupCode, productCode } = this.props.match.params;
		const { user, cart, productGroups, selectedTab, items, i18n} = this.props;
		if(!productGroups || !items) {
			return null;
		}
		return <Webshop
			productGroupCode={productGroupCode}
			productGroups={productGroups}
			imageBaseUrl="https://api.ptmd.nl/rest/images/v1"
			selectedTab={selectedTab}
			items={items}
			productCode={productCode}
			i18n={i18n}
			cart={cart}
			user={user}
		/>;
	}
}

const mapStateToProps = store => {
	const { productGroups, items, cart } = store.webshopState;
	const { user } = store.auth;
	return {
		productGroups,
		items,
		cart,
		user,
	};
};

export default connect(mapStateToProps)(WebshopContainer);
