import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormGroup, Col, Row } from 'reactstrap';
import { getCustomer, saveAddress } from '../../api';
import dispatchMessage from '../../reducers/actions/message';
import { Form, Input } from '../../components/form/Context';

export class CreateAddress extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			customer: null,
			address: {},
			redirect: false,
		};
		this.updateCustomer(props);
		this.save = this.save.bind(this);
	}

	componentWillReceiveProps(props) {
		this.updateCustomer(props);
	}

	updateCustomer(props) {
		const { id } = props.match.params;
		if(!this.state.customer || id !== this.state.customer.klantNummer) {
			getCustomer(id).then(customer => {
				this.setState({ customer });
			}).catch(err => {
				dispatchMessage('Failed to get customer', err);
			});
		}
	}

	save(e) {
		e.preventDefault();
		const { customer, address } = this.state;
		saveAddress(customer.klantNummer, address).then(() => {
			this.setState({ redirect: true });
		}).catch(err => {
			dispatchMessage('Failed to save address', err);
		});
	}

	render() {
		const { customer, redirect } = this.state;
		if(!customer) {
			return null;
		} else if(redirect) {
			return <Redirect to={`/staff/customers/${customer.klantNummer}`} push={true} />;
		}
		return <section className="container">
			<Form parent={this} object="address" onSubmit={this.save}>
				<Row>
					<Col lg={6}>
						<FormGroup>
							<Input value="naam" placeholder="Naam" required />
						</FormGroup>
						<FormGroup>
							<Input value="naam2" placeholder="Naam 2" />
						</FormGroup>
						<FormGroup>
							<Input value="telefoon" type="tel" placeholder="Telefoonnummer" />
						</FormGroup>
						<FormGroup>
							<Input value="fax" type="tel" placeholder="Faxnummer" />
						</FormGroup>
						<FormGroup>
							<Input value="email" type="email" placeholder="E-mail" />
						</FormGroup>
					</Col>
					<Col lg={6}>
						<FormGroup>
							<Input value="adres" placeholder="Adres" required />
						</FormGroup>
						<FormGroup>
							<Input value="adres2" placeholder="Adres 2" />
						</FormGroup>
						<FormGroup>
							<Input value="postcode" placeholder="Postcode" required />
						</FormGroup>
						<FormGroup>
							<Input value="stad" placeholder="Stad" />
						</FormGroup>
						<FormGroup>
							<Input value="land" type="select">
								{this.props.countries.map(country =>
									<option value={country._id} key={country._id}>{country.naam}</option>)}
							</Input>
						</FormGroup>
					</Col>
				</Row>
				<input type="submit" className="ptmd-button green-button" value="Save" />
			</Form>
		</section>;
	}
}

export const mapToProps = store => {
	const { countries } = store.crm;
	return { countries };
};

export default connect(mapToProps)(CreateAddress);
