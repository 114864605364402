import React, { Fragment } from 'react';
import {Button, Col, Input, InputGroup, InputGroupAddon, Row, FormGroup, Label} from 'reactstrap';
import { Link } from 'react-router-dom';
import store from '../../store';
import { setCart } from '../../reducers/actions/webshop';
import { getSize, getVolume } from './Webshop';
import FontAwesome from '../FontAwesome';
const PERCENT = 100;
const ROUND = 1e4;

export default class Cart extends React.Component {
	constructor(props) {
		super(props);
		this.state = { lines: props.cart };
	}

	componentDidUpdate(props) {
		if(props.cart !== this.props.cart) {
			this.setState({ lines: this.props.cart });
		}
	}

	updateLine(i, key, value) {
		const { lines } = this.state;
		const line = lines[i];
		this.setState({
			lines: lines.slice(0, i).concat(Object.assign({}, line, { [key]: value }), lines.slice(i + 1)),
		});
		let quantity = line.count;
		let discount = line.discount;
		let deliver = line.deliver;
		if(key === 'count') {
			const v = parseInt(value);
			if(!isNaN(v)) {
				quantity = v;
			}
		} else if(key === 'discount') {
			const v = parseInt(value);
			if(!isNaN(v)) {
				discount = v;
			}
		} else if(key === 'deliver') {
			deliver = value;
		}
		store.dispatch(setCart(line, quantity, discount, deliver));
	}

	render() {
		const { i18n, children, user, shipping, btw, readOnly, getPriceAndCbm, type, setType } = this.props;
		if(!user) {
			return null;
		}
		const { totalPrice, cbm } = getPriceAndCbm();
		const { lines } = this.state;
		const noDelivery = type === 'meeneem' || type === 'export';
		return (
			<div className="cart">
				<h2 className="cart-header">Uw winkelwagen</h2>

				<FormGroup>
					<Label check><input type="checkbox" disabled={readOnly} checked={type === 'meeneem'} onChange={e => {
						setType(e.target.checked ? 'meeneem' : null);
					}} /> Meeneem order</Label>
				</FormGroup>
				<FormGroup>
					<Label check><input type="checkbox" disabled={readOnly} checked={type === 'export'} onChange={e => {
						setType(e.target.checked ? 'export' : null);
					}} /> Export order</Label>
				</FormGroup>

				<div className="cart-container">
					{lines.map((line, i) => {
						const batch = line.item.quantity.reduce((b, q) => {
							if(line.batches.includes(q.batch)) {
								if(!b) {
									return q;
								}
								return Object.assign({}, b, {
									quantity: b.quantity + q.quantity,
								});
							}
							return b;
						}, null);
						if(!batch) {
							return null;
						}
						let description = `${line.item.artikelNummer} ${i18n.select(line.item.naam)}`;
						if(line.item.template && user.roles.includes('PURCHASE')) {
							description = <Link to={`/staff/template/${encodeURIComponent(line.item.template)}/edit`}>{description}</Link>;
						}
						return (
							<Row key={`${line.item.artikelNummer}_${batch.batch}_${line.locatie}`} className="product-line">
								<Col lg={1} md={2} xs={3}>
									<img className="item-image" src={`https://api.ptmd.nl/rest/images/v1/big/${line.item.artikelNummer}`} />
								</Col>

								<Col lg={11} md={10} xs={9}>
									<Row>
										<Col lg={4} md={12} xs={9}>
											<div className="item-text">
												<h4>{description}</h4>
												<p>
													{line.locatie ? `Locatie: ${line.locatie}` : ''}
												</p>
											</div>
										</Col>
										<Col lg={2} xs={6} sm={4} md={3}>
											<div className="item-quantity">
												{readOnly ? <span>{line.count} &times;</span> : <InputGroup>
													<Input
														value={line.count}
														type="number"
														min={1}
														max={batch.quantity}
														onChange={e => {
															this.updateLine(i, 'count', e.target.value);
														}}
													/>
													<InputGroupAddon className="count-addon" addonType="append">&times;</InputGroupAddon>
												</InputGroup>}
											</div>
										</Col>

										<Col lg={2} xs={6} sm={4} md={3}>
											<div className="item-quantity">
												{readOnly ? <span>{line.discount} %</span> : <InputGroup>
													<InputGroupAddon className="count-addon" addonType="prepend">&minus;</InputGroupAddon>
													<Input
														value={line.discount}
														type="number"
														min={0}
														max={100}
														disabled={!user.roles.includes('SALESPERSON')}
														onChange={e => {
															this.updateLine(i, 'discount', e.target.value);
														}}
													/>
													<InputGroupAddon className="count-addon" addonType="append">%</InputGroupAddon>
												</InputGroup>}
											</div>
										</Col>

										<Col xs={4} md={2} lg={1} className="text-center">
											{!readOnly && <Button color="danger" onClick={() => {
												store.dispatch(setCart(line, 0, 0, false));
											}}>
												<FontAwesome icon="trash" />
											</Button>}
										</Col>

										<Col xs={4} md={1} lg={1} className="text-lg-center">
											<span className="transport">
												<input
													type="checkbox"
													title="Bezorgen"
													disabled={readOnly || noDelivery}
													onChange={e => this.updateLine(i, 'deliver', e.target.checked) }
													checked={line.deliver && !noDelivery} />
											</span>
										</Col>

										<Col lg={2} xs={4} md={3} className="item-price text-right">
											{line.item && line.item.afmetingen ? <Fragment>
												{getSize(line.item.afmetingen.item)}<br />
												{getVolume(line.item.afmetingen)} m<sup>3</sup><br />
											</Fragment> : null}
											{i18n.format(line.count * line.item.price * (1 - line.discount / PERCENT))}
										</Col>
									</Row>
								</Col>
							</Row>
						);
					})}
				</div>

				<Row className="total-price">
					<Col xs={12}>
						<div className="cart-container">
							<Row>
								<Col xs={12} lg={{size: 3, offset: 9}}>
									<table className="total-price-table">
										<tbody>
											<tr>
												<td>Volume:</td>
												<td>{Math.round(cbm * ROUND) / ROUND} m<sup>3</sup></td>
											</tr>
											<tr>
												<td>Totaalbedrag:</td>
												<td>{i18n.format(totalPrice)}</td>
											</tr>
											{shipping !== undefined && <tr>
												<td>Verzendkosten:</td>
												<td>{isNaN(shipping) ? shipping : i18n.format(shipping)}</td>
											</tr>}
											{btw !== undefined && <tr>
												<td>BTW:</td>
												<td>{btw}%</td>
											</tr>}
											{btw !== undefined && <tr>
												<td>Totaal incl btw:</td>
												<td>{i18n.format((totalPrice + (isNaN(shipping) ? 0 : shipping)) * (1 + (isNaN(btw) ? 0 : btw) / PERCENT ))}</td>
											</tr>}
										</tbody>
									</table>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>

				{children}
			</div>
		);
	}
}
