import React from 'react';
import { login, logout } from '../api';
import { Button } from 'reactstrap';

export default class Auth extends React.Component {
	constructor(props) {
		super(props);
		this.login = this.login.bind(this);
		this.logout = this.logout.bind(this);
	}

	login() {
		login(this.props.login);
	}

	logout() {
		logout();
	}

	render() {
		const { user } = this.props;
		if(user) {
			return <section className="container">
				<button onClick={this.logout} className="btn btn-danger">Logout</button>
			</section>;
		}
		return <section className="container">
			<Button className="ptmd-button green-button" onClick={this.login}>Login</Button>
		</section>;
	}
}
