import React from 'react';
import { TabContent, TabPane, Nav, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import StamContainer from './StamContainer';
import VerkoopContainer from './VerkoopContainer';
import FacturenContainer from './FacturenContainer';
import InkoopContainer from './InkoopContainer';
import ItemContainer from './ItemContainer';
import UitleverContainer from './UitleverContainer';
import PickContainer from './PickContainer';
import Auth from '../../components/Auth';
import AssortimentContainer from './AssortimentContainer';
import StatsContainer from './StatsContainer';
import Customers from './Customers';
import AuditContainer from './AuditContainer';

function canSeeInkoop(roles) {
	return roles.includes('PURCHASE') || roles.includes('SALES');
}

function canSeeVerkoop(roles) {
	return roles.includes('SALES') || roles.includes('SALESPERSON');
}

export function canSeeStaff(roles) {
	return roles.some(role => ['PURCHASE', 'SALES', 'ADMIN', 'CRM', 'SALESPERSON', 'STOCK'].includes(role));
}

const TABS = {
	inkoop: {
		component: InkoopContainer,
		name: 'Inkoop',
		visible: v => v.inkoop,
	},
	verkoop: {
		component: VerkoopContainer,
		name: 'Orders',
		visible: v => v.verkoop,
	},
	facturen: {
		component: FacturenContainer,
		name: 'Gefactureerd',
		visible: v => v.verkoop,
	},
	uitleveren: {
		component: UitleverContainer,
		name: 'Pakken',
		visible: v => v.sales,
	},
	pick: {
		component: PickContainer,
		name: 'Aanmelden',
		visible: v => v.sales,
	},
	items: {
		component: ItemContainer,
		name: 'Voorraad',
		visible: v => v.verkoop || v.roles.includes('STOCK'),
	},
	stam: {
		component: StamContainer,
		name: 'Stam',
		visible: v => v.roles.includes('ADMIN'),
	},
	customers: {
		component: Customers,
		name: 'Klanten',
		visible: v => v.roles.some(role => role === 'CRM' || role === 'SALESPERSON'),
	},
	assortiment: {
		component: AssortimentContainer,
		name: 'Assortiment',
		visible: v => v.inkoop,
	},
	stats: {
		component: StatsContainer,
		name: 'Stats',
		visible: v => v.sales,
	},
	audit: {
		component: AuditContainer,
		name: 'Rapportage',
		visible: v => v.roles.some(role => role === 'STOCK' || role === 'SALES'),
	},
	logout: {
		component: Auth,
		name: 'Logout',
		visible: () => true,
		className: 'd-lg-none',
	},
};

export default class Staff extends React.Component {
	getTab(v) {
		if(v.verkoop) {
			return 'verkoop';
		} else if(v.inkoop) {
			return 'inkoop';
		} else if(v.roles.includes('ADMIN')) {
			return 'stam';
		}
		for(const tab in TABS) {
			if(TABS[tab].visible(v)) {
				return tab;
			}
		}
	}

	render() {
		const { user, i18n, match, history, location } = this.props;
		const inkoop = canSeeInkoop(user.roles);
		const verkoop = canSeeVerkoop(user.roles);
		const sales = user.roles.includes('SALES');
		const visibility = { inkoop, verkoop, sales, roles: user.roles };
		const tab = match && match.params && match.params.tab || this.getTab(visibility);
		return <section className="container-fluid staff">
			<Nav tabs className="container">
				{Object.keys(TABS).map(key => {
					const t = TABS[key];
					if(t.visible(visibility)) {
						return <NavItem className={t.className} key={key}>
							<Link
								to={`/staff/${key}`}
								className={tab === key ? 'active nav-link' : 'nav-link'}>
								{t.name}
							</Link>
						</NavItem>;
					}
					return null;
				})}
			</Nav>
			<TabContent activeTab={tab}>
				{Object.keys(TABS).map(key => {
					const t = TABS[key];
					if(tab === key && t.visible(visibility)) {
						const Comp = t.component;
						return <TabPane tabId={key} key={key}>
							<Comp i18n={i18n} user={user} history={history} location={location} />
						</TabPane>;
					}
					return null;
				})}
			</TabContent>
		</section>;
	}
}
