import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Input, FormGroup, Row, Col } from 'reactstrap';

export default class Step3 extends React.Component {
	render() {
		const { setState, user, aanbetaling, order, price, i18n } = this.props;
		return <form onSubmit={order}>
			<p>Totaal: {i18n.format(price)}</p>
			{user.roles.includes('SALESPERSON') && <Fragment>
				<h2>Aanbetaling</h2>
				<FormGroup>
					<Input type="number" value={aanbetaling} onChange={e => setState({ aanbetaling: e.target.value })} step={0.01} />
				</FormGroup>
			</Fragment>}
			<Row>
				<Col xs={12} md={6}>
					<Link to="/checkout/2" className="ptmd-button">Vorige</Link>
				</Col>
				<Col xs={12} md={6} className="text-right">
					<input type="submit" className="ptmd-button green-button" value="Plaats order" />
				</Col>
			</Row>
		</form>;
	}
}
