import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { getCustomer, checkVat } from '../../api';
import dispatchMessage from '../../reducers/actions/message';

export default class Customer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			customer: null,
			checking: false,
			result: null,
		};
		this.updateCustomer(props);
		this.checkVat = this.checkVat.bind(this);
	}

	componentWillReceiveProps(props) {
		this.updateCustomer(props);
	}

	updateCustomer(props) {
		const { id } = props.match.params;
		if(!this.state.customer || id !== this.state.customer.klantNummer) {
			getCustomer(id).then(customer => {
				this.setState({
					customer,
					result: customer.btwCheck || null,
				});
			}).catch(err => {
				dispatchMessage('Failed to get customer', err);
			});
		}
	}

	checkVat() {
		const { klantNummer } = this.state.customer;
		this.setState({ checking: true });
		checkVat(klantNummer).then(result => {
			this.setState({ result });
		}).catch(err => {
			dispatchMessage('Failed to check vat number', err);
			this.setState({ checking: false });
		});
	}

	renderNAW(customer) {
		return <Fragment>
			<dt className="col-md-4">Naam</dt>
			<dd className="col-md-8">{customer.naam}</dd>
			<dt className="col-md-4">Naam 2</dt>
			<dd className="col-md-8">{customer.naam2}</dd>
			<dt className="col-md-4">Adres</dt>
			<dd className="col-md-8">{customer.adres}</dd>
			<dt className="col-md-4">Adres 2</dt>
			<dd className="col-md-8">{customer.adres2}</dd>
			<dt className="col-md-4">Postcode</dt>
			<dd className="col-md-8">{customer.postcode}</dd>
			<dt className="col-md-4">Stad</dt>
			<dd className="col-md-8">{customer.stad}</dd>
			<dt className="col-md-4">Land</dt>
			<dd className="col-md-8">{customer.land}</dd>
		</Fragment>;
	}

	render() {
		const { customer, checking, result } = this.state;
		if(!customer) {
			return null;
		}
		return <section className="container">
			<h1>{customer.navCode || customer.klantNummer} {customer.naam}</h1>
			<dl className="row">
				{this.renderNAW(customer)}
				<dt className="col-md-4">Valuta</dt>
				<dd className="col-md-8">{customer.valuta}</dd>
				<dt className="col-md-4">Prijsgroep</dt>
				<dd className="col-md-8">{customer.prijsGroep}</dd>
				<dt className="col-md-4">Btw-nummer</dt>
				<dd className="col-md-8">
					{customer.btwNummer} {customer.btwNummer && !result
                      && <button disabled={checking} onClick={this.checkVat} className="btn btn-primary btn-sm">Check</button>}
				</dd>
				{result && <Fragment>
					<dt className="col-md-4">Btw check</dt>
					<dd className="col-md-8">
						{result.valid && <p className="text-success">{result.name}</p>}
						{!result.valid && <p className="text-danger">Invalide btw-nummer</p>}
					</dd>
				</Fragment>}
				<dt className="col-md-4">Taal</dt>
				<dd className="col-md-8">{customer.taal}</dd>
				<dt className="col-md-4">Telefoonnummer</dt>
				<dd className="col-md-8">{customer.contact.telefoon}</dd>
				<dt className="col-md-4">Mobiel nummer</dt>
				<dd className="col-md-8">{customer.contact.mobiel}</dd>
				<dt className="col-md-4">Faxnummer</dt>
				<dd className="col-md-8">{customer.contact.fax}</dd>
				<dt className="col-md-4">Website</dt>
				<dd className="col-md-8">{customer.contact.website}</dd>
				<dt className="col-md-4">E-mailadres</dt>
				<dd className="col-md-8">{customer.contact.email}</dd>
				<dt className="col-md-4">Factuur e-mailadres</dt>
				<dd className="col-md-8">{customer.contact.invoiceEmail}</dd>
				<dt className="col-md-4">Vertegenwoordiger</dt>
				<dd className="col-md-8">{customer.vertegenwoordiger}</dd>
			</dl>
			<h2>Adressen</h2>
			<p><Link to={`/staff/customers/${customer.klantNummer}/address/new`} className="ptmd-button green-button">Nieuw</Link></p>
			{customer.adressen.map(address => <Fragment key={address.code}>
				<dl className="row">
					{this.renderNAW(address)}
					<dt className="col-md-4">Telefoonnummer</dt>
					<dd className="col-md-8">{address.telefoon}</dd>
					<dt className="col-md-4">Faxnummer</dt>
					<dd className="col-md-8">{address.fax}</dd>
					<dt className="col-md-4">E-mailadres</dt>
					<dd className="col-md-8">{address.email}</dd>
				</dl>
				<Link to={`/staff/customers/${customer.klantNummer}/address/${address.code}/edit`} className="ptmd-button">Edit</Link>
				<hr />
			</Fragment>)}
		</section>;
	}
}
