import { SET_USER } from './action-types/auth';

const initialState = {
	user: null,
	roles: [],
};

export default (state = initialState, action) => {
	if(action.type === SET_USER) {
		const { user } = action.payload;
		return Object.assign({}, state, { user });
	} else if(action.type === 'reset') {
		return initialState;
	}
	return state;
};
