import React from 'react';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getItemTemplates, deleteItemTemplate } from '../../api';
import FontAwesome from '../../components/FontAwesome';
import Pagination from '../../components/Pagination';
import dispatchMessage from '../../reducers/actions/message';

export default class AssortimentContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = { templates: [] };
	}

	componentDidMount() {
		const { roles } = this.props.user;
		if(roles.includes('PURCHASE')) {
			getItemTemplates().then(templates => {
				this.setState({ templates });
			}).catch(err => {
				dispatchMessage('Failed to get item templates', err);
			});
		}
	}

	delete(id) {
		return e => {
			e.preventDefault();
			deleteItemTemplate(id).then(() => {
				const templates = this.state.templates.filter(({ _id }) => _id !== id);
				this.setState({ templates });
			}).catch(err => {
				dispatchMessage('Failed to delete item templates', err);
			});
		};
	}

	render() {
		return <section className="container">
			<h2>Item templates</h2>
			<Link to="/staff/template/new" className="ptmd-button green-button">Nieuw</Link>
			<Pagination
				data={this.state.templates}
				perPage={10}
				filter={(query, template) => template.artikelNummer && template.artikelNummer.includes(query)}
				component={templates =>
					<Table>
						<thead>
							<tr>
								<th>Code</th>
								<th colSpan="2">Sku</th>
								<th className="text-center">Bewerk</th>
								<th className="text-center">Verwijder</th>
							</tr>
						</thead>
						<tbody>
							{templates.map(template =>
								<tr key={template._id}>
									<td><Link to={`/staff/template/${template._id}`}>{template._id}</Link></td>
									<td>{template.artikelNummer}</td>
									<td>{template.artikelNummer && <img className="item-image" src={`https://api.ptmd.nl/rest/images/v1/small/${template.artikelNummer}.jpg`} />}</td>
									<td className="text-center"><Link to={`/staff/template/${template._id}/edit`}><FontAwesome icon="edit" /></Link></td>
									<td className="text-center"><a href="#" onClick={this.delete(template._id)}><FontAwesome icon="trash" /></a></td>
								</tr>
							)}
						</tbody>
					</Table>
				} />
		</section>;
	}
}
