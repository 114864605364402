export const WEBSHOP_UPDATE_CATEGORIES = 'WEBSHOP_UPDATE_CATEGORIES';
export const WEBSHOP_SET_SELECTED_TAB = 'WEBSHOP_SET_SELECTED_TAB';
export const WEBSHOP_UPDATE_ITEMS = 'WEBSHOP_UPDATE_ITEMS';
export const WEBSHOP_UPDATE_PRODUCT_GROUPS = 'WEBSHOP_UPDATE_PRODUCT_GROUPS';
export const WEBSHOP_ADD_TO_CART = 'WEBSHOP_ADD_TO_CART';
export const WEBSHOP_SET_CART = 'WEBSHOP_SET_CART';
export const WEBSHOP_CLEAR_CART = 'WEBSHOP_CLEAR_CART';
export const WEBSHOP_UPDATE_ORDERS = 'WEBSHOP_UPDATE_ORDERS';
export const WEBSHOP_SET_CUSTOMER = 'WEBSHOP_SET_CUSTOMER';
export const WEBSHOP_SET_CART_SHIPPING = 'WEBSHOP_SET_CART_SHIPPING';
export const WEBSHOP_SET_CART_ADDRESS = 'WEBSHOP_SET_CART_ADDRESS';
export const WEBSHOP_SET_VAT = 'WEBSHOP_SET_VAT';
export const WEBSHOP_SET_DELIVERY_WEEKS = 'WEBSHOP_SET_DELIVERY_WEEKS';
export const WEBSHOP_SET_TYPE = 'WEBSHOP_SET_TYPE';
export const WEBSHOP_SET_SURCHARGE = 'WEBSHOP_SET_SURCHARGE';
