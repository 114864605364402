function loadFromStorage(storage, key, shouldParseJson, def) {
	const item = storage.getItem(key);

	if (item) {
		if (shouldParseJson) {
			try {
				return JSON.parse(item);
			} catch (e) {
				return def;
			}
		}

		return item;
	}

	return def;
}

function saveToStorage(storage, key, value, shouldStringify) {
	if (shouldStringify) {
		value = JSON.stringify(value);
	}

	storage.setItem(key, value);
}

export function loadFromLocalStorage(key, shouldParseJson = false, def = []) {
	return loadFromStorage(localStorage, key, shouldParseJson, def);
}

export function saveToLocalStorage(key, value, shouldStringify = false) {
	return saveToStorage(localStorage, key, value, shouldStringify);
}

export function loadFromSessionStorage(key, shouldParseJson = false, def = []) {
	return loadFromStorage(sessionStorage, key, shouldParseJson, def);
}

export function saveToSessionStorage(key, value, shouldStringify = true) {
	return saveToStorage(sessionStorage, key, value, shouldStringify);
}
