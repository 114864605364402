import { connect } from 'react-redux';
import { CreateAddress, mapToProps } from './CreateAddress';

export class EditAddress extends CreateAddress {
	componentDidUpdate() {
		const { props, state } = this;
		if(state.customer && !Object.keys(state.address).length) {
			const { address } = props.match.params;
			this.setState({ address: state.customer.adressen.find(({ code }) => code === address) });
		}
	}
}

export default connect(mapToProps)(EditAddress);
