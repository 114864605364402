import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Auth from '../components/Auth';
import Checkout from '../containers/Checkout';
import SelectCustomer from '../containers/checkout/SelectCustomer';
import RouteWithProps from '../components/RouteWithProps';
import StaffRoutes from '../routes/StaffRoutes';
import WebshopContainer from '../containers/WebshopContainer';
import { canSeeStaff } from '../containers/staff/Staff';

export default ({ i18n, user }) => {
	return <Switch>
		<RouteWithProps path="/checkout/customer" component={SelectCustomer} i18n={i18n} />
		<RouteWithProps path="/checkout/:step?" component={Checkout} i18n={i18n} />
		<Route path="/logout" render={() => <Auth user={user} />} />
		<Route path="/login" render={() => <Redirect to="/" />} />
		{canSeeStaff(user.roles) && <RouteWithProps path="/staff" component={StaffRoutes} i18n={i18n} user={user} />}
		<RouteWithProps
			exact
			path="/:productGroupCode?/:productCode?"
			component={WebshopContainer}
			i18n={i18n} />
	</Switch>;
};
