import React, { Fragment } from 'react';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import { Collapse, Row, Col } from 'reactstrap';
import moment from 'moment';
import Cart from '../../components/webshop/Cart';
const MAX_WEEKS = 26;

export default class Step2 extends React.Component {
	constructor(props) {
		super(props);
		this.state = { open: false };
	}

	render() {
		const { open } = this.state;
		const { setState, cart, i18n, user, shipping, btw, delivery, weeks, startDate, extraWeeks, getPriceAndCbm, type } = this.props;
		const endDate = moment().add(MAX_WEEKS, 'weeks').endOf('week');
		return <Fragment>
			<button className="ptmd-button" onClick={e => {
				e.preventDefault();
				this.setState({ open: !open });
			}}>Bekijk winkelmandje</button>
			<Collapse isOpen={open}>
				<Cart
					readOnly={true}
					cart={cart}
					i18n={i18n}
					user={user}
					type={type}
					shipping={shipping}
					getPriceAndCbm={getPriceAndCbm}
					btw={btw} />
			</Collapse>
			<hr />
			<img alt="" src={`/images/uvf_manual_${weeks}.jpg`.toLowerCase()} />
			<h2>Leverdatum</h2>
			<Row>
				<Col md={6}>
					<DatePicker
						className="form-control"
						selected={delivery}
						dateFormat="YYYY/MM/DD"
						minDate={startDate}
						maxDate={endDate}
						onChange={date => setState({ delivery: date })}
					/>
				</Col>
				<Col md={6}>{extraWeeks} extra weken</Col>
			</Row>
			<Row>
				<Col xs={12} md={6}>
					<Link to="/checkout/1" className="ptmd-button">Vorige</Link>
				</Col>
				<Col xs={12} md={6} className="text-right">
					<Link to="/checkout/3" className="ptmd-button green-button">Volgende</Link>
				</Col>
			</Row>
		</Fragment>;
	}
}
