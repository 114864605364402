import React from 'react';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';

const RADIX = 10;

export default class ObjectInput extends React.Component {
	static get propTypes() {
		return {
			parent: PropTypes.object.isRequired,
			object: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
		};
	}

	constructor(props) {
		super(props);
		this.updateValue = this.updateValue.bind(this);
	}

	getPath() {
		const { value } = this.props;
		return Array.isArray(value) ? value : [ value ];
	}

	getValue() {
		const { parent, type, object, multiple } = this.props;
		let current = parent.state[object];
		for(const key of this.getPath()) {
			if(key in current) {
				current = current[key];
			} else {
				if(type === 'number') {
					return 0;
				} else if(type === 'checkbox') {
					return false;
				} else if(type === 'select' && multiple) {
					return [];
				}
				return '';
			}
		}
		return current;
	}

	getKey() {
		const { type } = this.props;
		if(type === 'checkbox') {
			return 'checked';
		}
		return 'value';
	}

	parseValue(e) {
		const value = e.target[this.getKey()];
		const { type, multiple } = this.props;
		if(type === 'number') {
			return parseFloat(value, RADIX);
		} else if(type === 'select' && multiple) {
			return [].map.call(e.target.selectedOptions, option => option.value);
		}
		return value;
	}

	updateValue(e) {
		const { parent, object } = this.props;
		const newValue = this.parseValue(e);
		parent.setState(state => {
			const copy = Object.assign({}, state[object]);
			let current = copy;
			const value = this.getPath();
			for(const i in value) {
				const key = value[i];
				if(i < value.length - 1) {
					if(key in current) {
						current[key] = Object.assign({}, current[key]);
					} else {
						current[key] = {};
					}
					current = current[key];
				} else {
					if(current[key] === newValue) {
						return state;
					}
					current[key] = newValue;
				}
			}
			return Object.assign({}, state, { [object]: copy });
		});
	}

	render() {
		const props = Object.assign({}, this.props);
		delete props.parent;
		delete props.object;
		delete props.value;
		const value = this.getValue();
		props[this.getKey()] = value;
		return <Input {...props}
			onChange={this.updateValue} />;
	}
}
