//Copyright © 2020 Cloudcontainers b.v. - All Rights Reserved
import React from 'react';
import { Link } from 'react-router-dom';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const PAGES = 2;

export default function Pages({ page, pages, getUrl, maxButtons = PAGES }) {
	const numbers = [];
	for(let i = page - maxButtons; i < page; i++) {
		if(i > 0) {
			numbers.push(i);
		}
	}
	let left = Math.min(maxButtons + (maxButtons - numbers.length), pages - page);
	for(let i = 0; i <= left; i++) {
		numbers.push(i + page);
	}
	const max = numbers[0];
	left = Math.max(max - maxButtons - maxButtons - 1 + numbers.length, 1);
	for(let i = max - 1; i > left; i--) {
		numbers.unshift(i);
	}
	return <Pagination listClassName="justify-content-center">
		<PaginationItem disabled={page <= 1} key="first">
			<PaginationLink tag={Link} first to={getUrl(1)} />
		</PaginationItem>
		<PaginationItem disabled={page <= 1} key="previous">
			<PaginationLink tag={Link} previous to={getUrl(Math.max(page - 1, 1))} />
		</PaginationItem>
		{numbers.map(p => {
			return <PaginationItem active={page === p} key={p}>
				<PaginationLink tag={Link} to={getUrl(p)}>
					{p}
				</PaginationLink>
			</PaginationItem>;
		})}
		<PaginationItem disabled={page >= pages} key="next">
			<PaginationLink tag={Link} next to={getUrl(Math.min(page + 1, pages))} />
		</PaginationItem>
		<PaginationItem disabled={page >= pages} key="last">
			<PaginationLink tag={Link} last to={getUrl(pages)} />
		</PaginationItem>
	</Pagination>;
}
