import React from 'react';
import { connect } from 'react-redux';
import Notification from '../components/Notification';

class ErrorContainer extends React.Component {
	render() {
		const { messages } = this.props;
		if(!messages.length) {
			return null;
		}
		return <aside className="notifications container">
			{messages.map(message =>
				<Notification key={message.id} message={message} />)}
		</aside>;
	}
}

export default connect(store => {
	const { messages } = store;
	return { messages };
})(ErrorContainer);
