import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';

const wrapperProps = {
	className: 'form-group dropdown',
};

const defaultRenderInput = props => <input {...props} className="form-control" />;

export default class AutocompleteInput extends React.Component {
	static get propTypes() {
		return {
			getItemValue: PropTypes.func.isRequired,
			onSelect: PropTypes.func.isRequired,
			onChange: PropTypes.func.isRequired,
			getItemKey: PropTypes.func.isRequired,
			items: PropTypes.array.isRequired,
		};
	}

	constructor(props) {
		super(props);
		this.state = {
			value: '',
		};
		this.onChange = this.onChange.bind(this);
		this.onSelect = this.onSelect.bind(this);
	}

	onChange(event, value) {
		this.setState({ value });
		this.props.onChange(event, value);
	}

	onSelect(value, item) {
		this.setState({ value });
		this.props.onSelect(value, item);
	}

	render() {
		const { getItemValue, items, getItemKey, renderInput, renderItem } = this.props;
		return <Autocomplete
			getItemValue={getItemValue}
			items={items}
			renderItem={(item, highlighted) =>
				<div key={getItemKey(item)} className={highlighted ? 'active dropdown-item' : 'dropdown-item'}>
					{renderItem ? renderItem(item) : getItemValue(item)}
				</div>
			}
			renderInput={renderInput || defaultRenderInput}
			renderMenu={its =>
				<div className={its.length ? 'dropdown-menu show' : 'dropdown-menu'}>{its}</div>
			}
			value={this.state.value}
			onSelect={this.onSelect}
			onChange={this.onChange}
			wrapperStyle={null}
			wrapperProps={wrapperProps} />;
	}
}
