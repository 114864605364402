import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'react-router-redux';

import store from './store';
import history from './history';

import App from './App';

import 'bootstrap';
import './scss/app.scss';

render(<Provider store={store}>
	<ConnectedRouter history={history}>
		<App />
	</ConnectedRouter>
</Provider>, document.getElementById('ptmd-app'));
