import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { Table, Form } from 'reactstrap';
import { createCreditNote } from '../../api';
import dispatchMessage from '../../reducers/actions/message';

function AmountLine({ amounts, aField, value, i18n, title, credit: onChange, edit }) {
	const { credit, amount } = amounts[aField];
	return <tr className="small">
		<td>
			<input type="checkbox" checked={credit} onChange={onChange(aField)} disabled={value === 0 && aField !== 'discount'} />
		</td>
		<td colSpan={5} className="text-right"><strong>{title}</strong></td>
		<td className="text-right">{credit ? <input
			type="number"
			max={aField === 'discount' ? Infinity : value}
			min={0}
			step={0.01}
			value={amount}
			className="form-control"
			onChange={edit(aField)} /> : i18n.format(value)}</td>
	</tr>;
}

function ItemLine(regel) {
	const { line, split, credit, i18n, creditLine, restock, restockLine, editItem } = regel;
	const rows = credit ? split.length + 1 : 1;
	const remainder = line.quantity - split.reduce((sum, s) => sum + (+s.quantity || 0), 0);
	return <Fragment>
		<tr>
			<td rowSpan={rows}>
				<input type="checkbox" checked={credit} onChange={creditLine} />
			</td>
			<td rowSpan={rows}>
				<img src={`https://api.ptmd.nl/rest/images/v1/small/${line.code}.jpg`} className="item-image" /><br />
				{line.code}
			</td>
			<td rowSpan={rows}>{i18n.select((line.items[0] || {}).naam)}</td>
			<td rowSpan={rows} className="text-right">{i18n.format(line.prijs.stuk)}</td>
			<td className="text-right">
				{credit ? <div className="form-group text-left">
					<strong>Terug op voorraad</strong>
					<input
						type="number"
						max={line.quantity}
						min={0}
						value={restock}
						className="form-control"
						onChange={restockLine} />
				</div> : line.quantity}
			</td>
			<td className="text-right">{line.prijs.korting.percentage}</td>
			<td rowSpan={rows} className="text-right">{i18n.format(line.prijs.totaal)}</td>
		</tr>
		{credit && split.map((s, n) => {
			return <tr key={n}>
				<td className="text-right">
					<div className="form-group">
						<div className="input-group">
							<input
								type="number"
								max={remainder + (+s.quantity || 0)}
								min={0}
								value={s.quantity}
								className="form-control"
								onChange={editItem(n, 'quantity')} />
							<div className="input-group-append">
								<span className="input-group-text">/ {line.quantity}</span>
							</div>
						</div>
					</div>
				</td>
				<td className="text-right">
					<input
						type="number"
						max={100}
						min={0}
						value={s.discount}
						className="form-control"
						onChange={editItem(n, 'discount')} />
				</td>
			</tr>;
		})}
	</Fragment>;
}

export default class CreditOrder extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			submitting: false,
			redirect: false,
			regels: props.order.regels.map(line => ({ line, credit: false, split: [{ quantity: 0, discount: 0 }], restock: 0 })),
			reason: '',
			amounts: {
				discount: { credit: false, amount: 0 },
				paid: { credit: false, amount: 0 },
				shipping: { credit: false, amount: 0 },
				vat: { credit: false, amount: 0 },
				surcharge: { credit: false, amount: 0 },
			},
		};
		this.credit = this.credit.bind(this);
		this.edit = this.edit.bind(this);
		this.submit = this.submit.bind(this);
	}

	credit(key) {
		return e => {
			const { amounts } = this.state;
			this.setState({ amounts: Object.assign({}, amounts, {
				[key]: Object.assign({}, amounts[key], {
					credit: e.target.checked,
				}),
			})});
		};
	}

	edit(key) {
		return e => {
			const { amounts } = this.state;
			this.setState({ amounts: Object.assign({}, amounts, {
				[key]: Object.assign({}, amounts[key], {
					amount: e.target.value,
				}),
			})});
		};
	}

	creditLine(i) {
		return e => {
			const { regels } = this.state;
			this.setState({
				regels: regels.slice(0, i).concat(Object.assign({}, regels[i], {
					credit: e.target.checked,
				}), this.state.regels.slice(i + 1)),
			});
		};
	}

	editLine(i, key) {
		return e => {
			const { regels } = this.state;
			this.setState({
				regels: regels.slice(0, i).concat(Object.assign({}, regels[i], {
					[key]: e.target.value,
				}), this.state.regels.slice(i + 1)),
			});
		};
	}

	editItem(i) {
		return (n, key) => {
			return e => {
				const { regels } = this.state;
				const lines = regels.slice();
				const regel = Object.assign({}, lines[i]);
				regel.split = regel.split.slice();
				const split = Object.assign({}, regel.split[n]);
				split[key] = e.target.value;
				regel.split[n] = split;
				lines[i] = regel;
				if(regel.split.findIndex(s => !s.discount) < 0) {
					regel.split.push({ quantity: 0, discount: 0 });
				}
				this.setState({ regels: lines });
			};
		};
	}

	submit(e) {
		e.preventDefault();
		const { regels, reason, amounts, submitting } = this.state;
		const lines = [];
		regels.forEach(r => {
			if(r.credit) {
				r.split.forEach(split => {
					if(split.quantity > 0) {
						lines.push({
							quantity: parseInt(split.quantity),
							discount: parseFloat(split.discount),
							restock: parseInt(r.restock),
							original: {
								quantity: r.line.quantity,
								discount: r.line.discount,
								code: r.line.code,
								batches: r.line.batches,
							},
						});
					}
				});
			}
		});
		const note = {
			reason: reason.trim(),
			lines, amounts: {},
		};
		Object.keys(amounts).forEach(key => {
			if(amounts[key].credit && amounts[key].amount > 0) {
				note.amounts[key] = parseFloat(amounts[key].amount);
			}
		});
		if(submitting || !note.reason || !note.lines.length && !Object.keys(note.amounts).length) {
			return;
		}
		this.setState({ submitting: true });
		createCreditNote(this.props.order._id, note).then(() => {
			this.setState({ redirect: true });
		}).catch(err => {
			dispatchMessage('Failed to credit order', err);
		}).then(() => {
			this.setState({ submitting: false });
		});
	}

	render() {
		const { order, customer, i18n } = this.props;
		const { regels, reason, amounts, submitting, redirect } = this.state;
		if(redirect) {
			return <Redirect to={`/staff/orders/${order._id}`} />;
		}
		return <section className="container">
			<h2>{order._id}</h2>
			<p>{new Date(order.datum).toLocaleString()}</p>
			{order.referentie && <p>Referentie: {order.referentie}</p>}
			{customer && <p>{customer.navCode || customer.klantNummer} {customer.naam}</p>}
			<Form onSubmit={this.submit}>
				<Table>
					<thead>
						<tr>
							<th>Crediteer</th>
							<th>Code</th>
							<th>Naam</th>
							<th className="text-right">Stukprijs</th>
							<th className="text-right">Stuks</th>
							<th className="text-right">Korting %</th>
							<th className="text-right">Prijs</th>
						</tr>
					</thead>
					<tbody>
						{regels.map((regel, i) =>
							<ItemLine key={i} i={i} {...regel} restockLine={this.editLine(i, 'restock')} creditLine={this.creditLine(i)} i18n={i18n} editItem={this.editItem(i)} />
						)}
						<tr className="small">
							<td colSpan={6} className="text-right"><strong>Totaal</strong></td>
							<td className="text-right">{i18n.format(order.prijs.euro.subtotaal)}</td>
						</tr>
						<AmountLine amounts={amounts} aField="discount" value={order.prijs.euro.korting} i18n={i18n} title="Korting" credit={this.credit} edit={this.edit} />
						{'aanbetaling' in order && <tr>
							<td colSpan={6} className="text-right"><strong>Aanbetaling</strong></td>
							<td className="text-right">{i18n.format(order.aanbetaling)}</td>
						</tr>}
						<AmountLine amounts={amounts} aField="shipping" value={order.prijs.euro.transport || '---'} i18n={i18n} title="Verzendkosten" credit={this.credit} edit={this.edit} />
						<tr className="small">
							<td colSpan={6} className="text-right"><strong>Totaal excl. btw</strong></td>
							<td className="text-right">{i18n.format(order.prijs.euro.totaal - (order.aanbetaling || 0))}</td>
						</tr>
						<AmountLine amounts={amounts} aField="vat" value={order.prijs.euro.btw} i18n={i18n} title="BTW" credit={this.credit} edit={this.edit} />
						{order.prijs.euro.toeslag > 0 && <AmountLine amounts={amounts} aField="surcharge" value={order.prijs.euro.toeslag} i18n={i18n} title="Toeslag" credit={this.credit} edit={this.edit} />}
						<tr>
							<td colSpan={6} className="text-right"><strong>Totaal incl. btw</strong></td>
							<td className="text-right">{i18n.format(order.prijs.euro.generaalTotaal - (order.aanbetaling || 0))}</td>
						</tr>
					</tbody>
				</Table>
				<textarea className="form-control" placeholder="Reden" value={reason} onChange={e => this.setState({ reason: e.target.value })} required></textarea>
				<input
					className="ptmd-button"
					value="Maak credit nota"
					type="submit"
					disabled={submitting || !regels.some(r => r.credit) && !Object.keys(amounts).some(key => amounts[key].credit)} />
			</Form>
		</section>;
	}
}
