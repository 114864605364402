import React from 'react';
import { FormGroup, Table, Row, Col } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { LANGUAGES } from '../../util/locale';
import { Form, Input } from '../../components/form/Context';
import { saveItemTemplate } from '../../api';
import dispatchMessage from '../../reducers/actions/message';

export class CreateItemTemplate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			template: {},
			redirect: null,
		};
		this.submit = this.submit.bind(this);
	}

	submit(e) {
		e.preventDefault();
		const { template } = this.state;
		if(Object.keys(template).length) {
			saveItemTemplate(template).then(redirect => {
				this.setState({ redirect });
			}).catch(err => {
				dispatchMessage('Failed to save item template', err);
			});
		}
	}

	render() {
		const { redirect } = this.state;
		const { productGroups } = this.props;
		if(redirect !== null) {
			return <Redirect to={`/staff/template/${redirect}`} push={true} />;
		}
		return <section className="container-fluid">
			<Form onSubmit={this.submit} parent={this} object="template">
				<Row>
					<Col md={6}>
						<FormGroup>
							<Input placeholder="Artikelnummer" value="artikelNummer" />
						</FormGroup>
					</Col>
					<Col md={6}>
						<FormGroup>
							<Input placeholder="EAN code" value="eanCode" />
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col md={6}>
						<h3>Marketing</h3>
						<FormGroup>
							<Input placeholder="Collectie" value={['marketing', 'collectie']} />
						</FormGroup>
						<FormGroup>
							<Input placeholder="Productgroep" type="select" value={['marketing', 'productGroep']}>
								<option value=""></option>
								{productGroups.map(group => <option key={group.code} value={group.code}>{group.name}</option>)}
							</Input>
						</FormGroup>
						<h3>Prijzen</h3>
						<Table>
							<thead>
								<tr><th>Type</th><th>Prijs</th></tr>
							</thead>
							<tbody>
								<tr><td>Verkoopprijs</td><td><Input type="number" step={0.01} value={['prijzen', 'verkoop']} /></td></tr>
								<tr><td>Kostprijs</td><td><Input type="number" step={0.01} value={['prijzen', 'kost']} /></td></tr>
							</tbody>
						</Table>
					</Col>
					<Col xl={6}>
						<h3>Locatie</h3>
						<FormGroup>
							<Input value="locatie" min={2} max={2} placeholder="Locatie (bijv. 1A)" />
						</FormGroup>
						<h3>Afmetingen</h3>
						<Table>
							<thead>
								<tr>
									<th>Afmeting</th>
									<th>Hoogte</th>
									<th>Breedte</th>
									<th>Lengte</th>
									<th>Gewicht</th>
									<th>CBM</th>
								</tr>
							</thead>
							<tbody>
								{['item', 'innerbox', 'outerbox'].map(key =>
									<tr key={key}>
										<th>{key}</th>
										<td><Input type="number" step={0.01} value={['afmetingen', key, 'height']} /></td>
										<td><Input type="number" step={0.01} value={['afmetingen', key, 'width']} /></td>
										<td><Input type="number" step={0.01} value={['afmetingen', key, 'length']} /></td>
										<td><Input type="number" step={0.01} value={['afmetingen', key, 'weight']} /></td>
										<td><Input type="number" step={0.01} value={['afmetingen', key, 'cbm']} /></td>
									</tr>
								)}
							</tbody>
						</Table>
					</Col>
				</Row>
				<h3>Vertalingen</h3>
				<Table>
					<thead>
						<tr><th>Taal</th><th>Naam</th><th>Omschrijving</th></tr>
					</thead>
					<tbody>
						{LANGUAGES.map(language =>
							<tr key={language.code}>
								<td>{language.name}</td>
								<td><Input placeholder="Naam" value={['naam', language.code]} /></td>
								<td><Input type="textarea" placeholder="Omschrijving" value={['omschrijving', language.code]} /></td>
							</tr>
						)}
					</tbody>
				</Table>
				<input type="submit" className="ptmd-button green-button" value="Save" />
			</Form>
		</section>;
	}
}

export const mapToProps = connect(store => {
	const { productGroups } = store.webshopState;
	return { productGroups };
});

export default mapToProps(CreateItemTemplate);
