import React from 'react';
import { Redirect } from 'react-router-dom';
import { Form, Input } from '../../components/form/Context';
import { saveCurrency } from '../../api';
import dispatchMessage from '../../reducers/actions/message';

export default class CreateCurrency extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currency: {
				factor: 1,
			},
			redirect: null,
			edit: false,
		};
		this.submit = this.submit.bind(this);
	}

	submit(e) {
		e.preventDefault();
		saveCurrency(this.state.currency).then(redirect => {
			this.setState({ redirect });
		}).catch(err => {
			dispatchMessage('Failed to save currency', err);
		});
	}

	render() {
		const { currency, redirect, edit } = this.state;
		if(redirect !== null) {
			return <Redirect to={`/staff/currencies/${redirect}`} push={true} />;
		}
		return <section className="container">
			<Form parent={this} object="currency" onSubmit={this.submit}>
				<h1>{currency._id}</h1>
				<dl className="row">
					<dt className="col-md-4">Valutacode</dt>
					<dd className="col-md-8"><Input value="_id" required maxLength={3} minLength={3} disabled={edit} /></dd>
					<dt className="col-md-4">Omrekenfactor</dt>
					<dd className="col-md-8"><Input value="factor" type="number" step={0.001} min={0} required /></dd>
				</dl>
				<input type="submit" className="ptmd-button green-button" value="Save" />
			</Form>
		</section>;
	}
}
