import React from 'react';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getUnsentOrder, setOrderLoaded } from '../../api';
import dispatchMessage from '../../reducers/actions/message';

class UitleverOrder extends React.Component {
	constructor(props) {
		super(props);
		this.loadOrder = this.loadOrder.bind(this);
	}

	loadOrder() {
		setOrderLoaded(this.props.order._id, true).catch(err => {
			dispatchMessage('Failed to load order', err);
		});
	}

	render() {
		const { order, i18n } = this.props;
		return <section className="container">
			<h1>{order._id}</h1>
			{!order.ingeladen && <Link to={`/staff/pick/orders/${order._id}`} className="ptmd-button" onClick={this.loadOrder}>Ingeladen</Link>}
			<Table>
				<thead>
					<tr>
						<th>Item</th>
						<th>Naam</th>
						<th>Hoeveelheid</th>
						<th>Bezorgen</th>
						<th>Locaties</th>
					</tr>
				</thead>
				<tbody>
					{order.regels.map((line, i) => <tr key={i}>
						<td>
							<img src={`https://api.ptmd.nl/rest/images/v1/small/${line.code}.jpg`} className="item-image" /><br />
							{line.code}
						</td>
						<td>{line.items[0] && i18n.select(line.items[0].naam)}</td>
						<td>{line.quantity}</td>
						<td><input type="checkbox" disabled checked={line.deliver} /></td>
						<td>{line.locaties && line.locaties.join(', ')}</td>
					</tr>)}
				</tbody>
			</Table>
			{!order.ingeladen && <Link to={`/staff/pick/orders/${order._id}`} className="ptmd-button" onClick={this.loadOrder}>Ingeladen</Link>}
		</section>;
	}
}

export default class Page extends React.Component {
	constructor(props) {
		super(props);
		this.state = { order: null };
	}

	componentDidMount() {
		getUnsentOrder(this.props.match.params.id).then(order => {
			order.regels.sort((lhs, rhs) => rhs.deliver - lhs.deliver);
			this.setState({ order });
		}).catch(err => {
			dispatchMessage('Failed to get order', err);
		});
	}

	render() {
		const { order } = this.state;
		if(!order) {
			return null;
		}
		return <UitleverOrder order={order} i18n={this.props.i18n} />;
	}
}
