import React from 'react';
import {Col, Row} from 'reactstrap';
import {push} from 'react-router-redux';
import store from '../../store';
import { getSize, getVolume } from './Webshop';

export default class Items extends React.Component {
	onClick(productGroupCode, productCode) {
		const path = `/${productGroupCode}/${productCode}`;
		store.dispatch(push(path));
	}

	render() {
		const { items, i18n, noQuery } = this.props;
		return (
			<div id="webshop-items">
				{items.length ? <Row>
					{items.map(item => {
						const onClick = () => this.onClick(item.marketing.productGroep, item.artikelNummer);
						return <Col key={item.artikelNummer} xs={12} md={3} className="webshop-column">
							<div className="inner-webshop-column webshop-item" onClick={onClick}>
								<img className="item-image" src={`https://api.ptmd.nl/rest/images/v1/big/${item.artikelNummer}.jpg`} />

								<p className="item-description">{i18n.select(item.naam)}</p>

								<p className="item-meta">
									<span>{item.artikelNummer}</span>
									<span>{item.marketing.collectie}</span>
									<span>{getSize(item.afmetingen.item)}</span>
									<span>{getVolume(item.afmetingen)} m<sup>3</sup></span><br />
									<span>{i18n.format(item.price)}</span>
								</p>
							</div>
						</Col>;
					})}
				</Row>
					: <p>{noQuery ? '' : 'Geen resultaten gevonden'}</p>
				}
			</div>
		);
	}
}
