import React, { Fragment } from 'react';
import {Navbar, Nav, Collapse, NavItem} from 'reactstrap';
import {Link} from 'react-router-dom';
import { canSeeStaff } from '../../containers/staff/Staff';

export default function Navigation({ user, isOpen }) {
	return <div className="navigation-container">
		<Navbar id="main-navbar" expand="lg">
			<Collapse navbar isOpen={isOpen} className="no-transition justify-content-center">
				<Nav navbar>
					<NavItem>
						<Link to="/" className="nav-link">Home</Link>
					</NavItem>
					{user && <Fragment>
						{user.roles.includes('CUSTOMER') && <NavItem>
							<Link to="/orders" className="nav-link">Mijn PTMD</Link>
						</NavItem>}
						{canSeeStaff(user.roles) && <NavItem>
							<Link to="/staff" className="nav-link staff">Medewerkers</Link>
						</NavItem>}
						<NavItem className="d-lg-none">
							<Link to="/logout" className="nav-link">Logout</Link>
						</NavItem>
					</Fragment>}
				</Nav>
			</Collapse>
		</Navbar>
	</div>;
}
