import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Staff from '../containers/staff/Staff';
import ItemTemplate from '../containers/staff/ItemTemplate';
import CreateItemTemplate from '../containers/staff/CreateItemTemplate';
import EditItemTemplate from '../containers/staff/EditItemTemplate';
import RouteWithProps from '../components/RouteWithProps';
import StaffOrderContainer from '../containers/staff/StaffOrderContainer';
import Customer from '../containers/staff/Customer';
import CreateAddress from '../containers/staff/CreateAddress';
import EditAddress from '../containers/staff/EditAddress';
import CreateCurrency from '../containers/staff/CreateCurrency';
import EditCurrency from '../containers/staff/EditCurrency';
import Currency from '../containers/staff/Currency';
import Item from '../containers/staff/Item';
import UitleverOrder from '../containers/staff/UitleverOrder';
import UitleverList from '../containers/staff/UitleverList';
import PickOrder from '../containers/staff/PickOrder';
import { fetchPriceGroups, fetchCurrencies, fetchCountries, fetchLanguages, fetchProductGroups } from '../api';

export default class StaffRoutes extends React.Component {
	componentDidMount() {
		const { roles } = this.props.user;
		fetchProductGroups();
		fetchCountries();
		fetchCurrencies();
		if(roles.some(role => role === 'CRM' || role === 'SALESPERSON')) {
			fetchLanguages();
			fetchPriceGroups();
		}
	}

	render() {
		const { i18n, user } = this.props;
		const children = [];
		if(user.roles.some(role => role === 'CRM' || role === 'SALESPERSON')) {
			children.push(<Route path="/staff/customers/:id/address/new" component={CreateAddress} />);
			children.push(<Route path="/staff/customers/:id/address/:address" component={EditAddress} />);
			children.push(<Route path="/staff/customers/:id" component={Customer} />);
		}
		if(user.roles.includes('SALES')) {
			children.push(<RouteWithProps path="/staff/uitleveren/orders/:id" component={UitleverOrder} i18n={i18n} />);
			children.push(<RouteWithProps path="/staff/uitleveren/orders" component={UitleverList} i18n={i18n} />);
			children.push(<Route path="/staff/pick/orders/:id" component={PickOrder} />);
			children.push(<RouteWithProps path="/staff/orders/:order/credit" component={StaffOrderContainer} i18n={i18n} user={user} credit />);
		}
		if(user.roles.some(role => role === 'SALES' || role === 'SALESPERSON')) {
			children.push(<RouteWithProps path="/staff/orders/:order" component={StaffOrderContainer} i18n={i18n} user={user} />);
		}
		if(user.roles.some(role => role === 'SALES' || role === 'STOCK')) {
			children.push(<RouteWithProps path="/staff/items/:id" component={Item} user={user} />);
		}
		if(user.roles.includes('PURCHASE')) {
			children.push(<Route path="/staff/template/new" component={CreateItemTemplate} />);
			children.push(<Route path="/staff/template/:template/edit" component={EditItemTemplate} />);
			children.push(<Route path="/staff/template/:template" component={ItemTemplate} />);
		}
		if(user.roles.includes('ADMIN')) {
			children.push(<Route path="/staff/currencies/new" component={CreateCurrency} />);
			children.push(<Route path="/staff/currencies/:id/edit" component={EditCurrency} />);
			children.push(<Route path="/staff/currencies/:id" component={Currency} />);
		}
		children.push(<RouteWithProps path="/staff/:tab?" exact component={Staff} i18n={i18n} user={user} />);
		children.push(<Route render={() => <Redirect to="/staff" />} />);
		return React.createElement(Switch, null, ...children);
	}
}
