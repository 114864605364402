import React, { Fragment } from 'react';
import { InputGroup, FormGroup } from 'reactstrap';
import { Link } from 'react-router-dom';
import { findCustomer } from '../../api';
import dispatchMessage from '../../reducers/actions/message';
import withCart from '../../reducers/cart';
import Autocomplete from '../../components/form/Autocomplete';
import AddressSelection from '../../components/orders/AddressSelection';
const WAIT = 500;

class SelectCustomer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			customers: [],
		};
		this.findCustomer = this.findCustomer.bind(this);
		this.selectCustomer = this.selectCustomer.bind(this);
		this.timeout = 0;
	}

	findCustomer(event, value) {
		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			findCustomer(value).then(customers => {
				this.setState({ customers });
			}).catch(err => {
				dispatchMessage('Failed to find customers', err);
			});
		}, WAIT);
	}

	selectCustomer(value, customer) {
		this.props.setCustomer(customer);
	}

	render() {
		const { user, address, customer } = this.props;
		const { customers } = this.state;
		const canOrder = user.roles.includes('CUSTOMER') || customer;
		return <section className="container">
			{user.roles.includes('SALESPERSON') && <Fragment>
				<h2>Klant</h2>
				<FormGroup>
					<Autocomplete
						renderInput={props => <InputGroup>
							<input {...props} className="form-control" />
						</InputGroup>}
						renderItem={c => <Fragment>{c.naam} <small>{c.stad} {c.land}</small></Fragment>}
						getItemValue={c => c.naam}
						getItemKey={c => c.klantNummer}
						items={customers}
						onSelect={this.selectCustomer}
						onChange={this.findCustomer} />
				</FormGroup>
			</Fragment>}
			{canOrder && <Fragment>
				<h2>Adres</h2>
				<AddressSelection
					address={address}
					addresses={customer ? customer.adressen : user.adressen}
					customer={customer || user}
					onChange={this.props.setAddress} />
			</Fragment>}
			{address.adres ? <Link to="/" className="ptmd-button green-button">Volgende</Link> : <p>Ongeldig adres</p>}
		</section>;
	}
}

export default withCart(SelectCustomer);
