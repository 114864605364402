import React from 'react';
import PropTypes from 'prop-types';

export default class FontAwesome extends React.Component {
	static get propTypes() {
		return {
			type: PropTypes.string,
			icon: PropTypes.string.isRequired,
			spin: PropTypes.bool,
			className: PropTypes.string,
		};
	}

	static get defaultProps() {
		return {
			type: 'fa',
			spin: false,
			className: '',
		};
	}

	constructor(props) {
		super(props);
		this.id = `fa-${Date.now()}-${Math.random()}`;
	}

	componentDidUpdate() {
		const icon = document.getElementById(this.id);

		if (icon) {
			const classList = (icon.getAttribute('class') || '').split(' ');
			const faSpinIndex = classList.indexOf('fa-spin');

			if (faSpinIndex >= 0 && !this.props.spin) {
				classList.splice(faSpinIndex, 1);
			} else if (faSpinIndex < 0 && this.props.spin) {
				classList.push('fa-spin');
			}

			try {
				icon.dataset.icon = this.props.icon;
			} catch (e) {
				icon.setAttribute('data-icon', this.props.icon);
			}

			icon.setAttribute('class', [...new Set(classList
				.concat(this.props.className.split(' ')))].join(' '));
		}
	}

	render() {
		const classes = this.props.className.split(' ').concat([
			this.props.type,
			`fa-${this.props.icon}`,
		]);

		if (this.props.spin) {
			classes.push('fa-spin');
		}

		return <i id={this.id} className={classes.join(' ')} />;
	}
}
