import React, { Fragment } from 'react';
import { Form, FormGroup, Input, Button, Collapse, ListGroup, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import FontAwesome from '../FontAwesome';
const MIN_LENGTH = 3;

export default class ItemFilters extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			filtersShown: false,
			minPrice: 0,
			maxPrice: 0,
			priceFiltersShown: false,
			query: '',
		};
		this.toggleFilters = this.toggleFilters.bind(this);
		this.togglePriceFilters = this.togglePriceFilters.bind(this);
		this.updateMinPrice = this.updateMinPrice.bind(this);
		this.updateMaxPrice = this.updateMaxPrice.bind(this);
	}

	componentWillReceiveProps(props) {
		if(!this.state.maxPrice && props.maxPrice) {
			this.setState({
				maxPrice: props.maxPrice,
			});
		}
	}

	toggleFilters(e) {
		e.preventDefault();
		this.setState({
			filtersShown: !this.state.filtersShown,
		});
	}

	togglePriceFilters(e) {
		e.preventDefault();
		this.setState({
			priceFiltersShown: !this.state.priceFiltersShown,
		});
	}

	updateMinPrice(e) {
		const minPrice = e.target.value;
		const maxPrice = Math.max(this.state.maxPrice, e.target.value);
		this.updateFilters({
			minPrice, maxPrice,
		});
	}

	updateMaxPrice(e) {
		const minPrice = Math.min(this.state.minPrice, e.target.value);
		const maxPrice = e.target.value;
		this.updateFilters({
			minPrice, maxPrice,
		});
	}

	updateFilters(filters, force = true) {
		this.setState(filters);
		if(force) {
			this.props.setFilters(filters);
		}
	}

	renderCategories() {
		const { productGroups, i18n } = this.props;
		return <ListGroup>
			{productGroups.map(group =>
				<li key={group.code} className="productgroup">
					<Link to={`/${group.code}`}>
						{i18n.select(group)}
					</Link>
				</li>
			)}
		</ListGroup>;
	}

	render() {
		const { sortOptions, searchOnly } = this.props;
		const { filtersShown, minPrice, maxPrice, priceFiltersShown, categoryFiltersShown, query } = this.state;
		return <Form className="filters" onSubmit={e => {
			e.preventDefault();
			this.updateFilters({ query });
		}}>
			<FormGroup>
				<Input
					type="search"
					placeholder="Zoek"
					value={query}
					onChange={e => this.updateFilters({ query: e.target.value }, e.target.value.length > MIN_LENGTH)} />
			</FormGroup>
			{!searchOnly && <Fragment>
				<Row>
					<Col xs={4}>
						<Button className="filter-button" color="link" onClick={this.toggleFilters}>Filter</Button>
					</Col>
					<Col xs={8}>
						<Input type="select" onChange={e => this.updateFilters({ sort: e.target.value })}>
							{Object.keys(sortOptions).map(key =>
								<option key={key} value={key}>{key}</option>
							)}
						</Input>
					</Col>
				</Row>
				<Collapse isOpen={filtersShown} className="sub-filters">
					<div className="small-categories">
						<Button className="sub-filter-button" color="link" onClick={this.toggleCategoryFilters}>
                            Categorieën <FontAwesome icon={categoryFiltersShown ? 'caret-up' : 'caret-down'} />
						</Button>
						<Collapse isOpen={categoryFiltersShown} className="sub-filter-filter">
							{this.renderCategories()}
						</Collapse>
					</div>
					<Button className="sub-filter-button" color="link" onClick={this.togglePriceFilters}>
                        Prijzen <FontAwesome icon={priceFiltersShown ? 'caret-up' : 'caret-down'} />
					</Button>
					<Collapse isOpen={priceFiltersShown} className="sub-filter-filter">
						<Row>
							<Col lg={6}>
								<FormGroup>
                                    Minimaal
									<Input
										type="number"
										min={0}
										max={this.props.maxPrice}
										step={0.01} value={minPrice}
										onChange={this.updateMinPrice}
									/>
								</FormGroup>
							</Col>
							<Col lg={6}>
								<FormGroup>
                                    Maximaal
									<Input
										type="number"
										min={0}
										max={this.props.maxPrice}
										step={0.01}
										value={maxPrice}
										onChange={this.updateMaxPrice}
									/>
								</FormGroup>
							</Col>
						</Row>
					</Collapse>
				</Collapse>
				<div className="large-categories">
					{this.renderCategories()}
				</div>
			</Fragment>}
		</Form>;
	}
}
