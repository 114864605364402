import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import FontAwesome from './FontAwesome';
import store from '../store';
import { removeMessage } from '../reducers/actions/message';

export default class Notification extends React.Component {
	constructor(props) {
		super(props);
		this.delete = this.delete.bind(this);
	}

	componentWillMount() {
		const { message, err } = this.props.message;
		if(err) {
			console.error(message, err);
		}
	}

	delete() {
		store.dispatch(removeMessage(this.props.message.id));
	}

	render() {
		const { message } = this.props.message;
		return <Row className="notification">
			<Col xs={10} sm={11}>
				<p>{message}</p>
			</Col>
			<Col xs={2} sm={1} className="text-right">
				<Button color="danger" size="sm" onClick={this.delete}>
					<FontAwesome icon="times" />
				</Button>
			</Col>
		</Row>;
	}
}
