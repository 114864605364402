import {SET_SCROLL_TO} from './action-types/navigation';

const initialState = {
	scrollTo: null,
};

const reducer = (state = initialState, action) => {
	if (action.type === SET_SCROLL_TO) {
		return Object.assign({}, state, { scrollTo: action.payload.scrollTo });
	} else if(action.type === 'reset') {
		return initialState;
	}
	return state;
};

export default reducer;
