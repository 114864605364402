import React from 'react';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import Pagination from '../../components/Pagination';
import { getCurrencies } from '../../api';
import dispatchMessage from '../../reducers/actions/message';
import FontAwesome from '../../components/FontAwesome';

export default class Currencies extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currencies: [],
		};
	}

	componentDidMount() {
		getCurrencies().then(currencies => {
			currencies.sort((lhs, rhs) => lhs._id < rhs._id ? -1 : lhs._id > rhs._id ? 1 : 0);
			this.setState({ currencies });
		}).catch(err => {
			dispatchMessage('Failed to get currencies', err);
		});
	}

	render() {
		const { currencies } = this.state;
		return <Pagination
			filter={(query, currency) => currency._id.toLowerCase().includes(query)}
			data={currencies}
			perPage={10}
			component={page =>
				<Table>
					<thead>
						<tr>
							<th>Code</th>
							<th className="text-right">Factor</th>
							<th className="text-center">Bewerk</th>
						</tr>
					</thead>
					<tbody>
						{page.map(currency =>
							<tr key={currency._id}>
								<td><Link to={`/staff/currencies/${currency._id}`}>{currency._id}</Link></td>
								<td className="text-right">{currency.factor}</td>
								<td className="text-center"><Link to={`/staff/currencies/${currency._id}/edit`}><FontAwesome icon="edit" /></Link></td>
							</tr>
						)}
					</tbody>
				</Table>
			} />;
	}
}
