import React from 'react';
import { connect } from 'react-redux';
import { FormGroup, Row, Col, InputGroupAddon } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Input, InputGroup } from '../form/Context';
import { fetchCountries } from '../../api';

class AddressSelection extends React.Component {
	constructor(props) {
		super(props);
		this.state = this.getDefaultState(props);
	}

	getDefaultState(props = this.props) {
		let selectedAddress;
		if(props.address && !props.address.code && Object.keys(props.address).length) {
			selectedAddress = 'new';
		} else {
			selectedAddress = props.address && props.address.code || 'self';
		}
		let address;
		if(selectedAddress === 'new') {
			address = props.address || {};
		} else if(props.customer) {
			if(selectedAddress === 'self') {
				address = this.getSelf(props.customer);
			} else {
				address = props.addresses.find(a => a.code === selectedAddress);
			}
		}
		if(!address) {
			address = {};
		}
		return {
			address,
			selectedAddress: address.code || 'new',
			newAddress: {},
		};
	}

	componentDidMount() {
		if(!this.props.countries.length) {
			fetchCountries();
		}
		this.props.onChange(this.state.address);
	}

	getSelf(customer) {
		const { naam, naam2, email, adres, adres2, postcode, stad, land } = customer;
		const { telefoon } = customer.contact;
		const address = { naam, naam2, telefoon, email, adres, adres2, postcode, stad, land, code: 'self' };
		Object.keys(address).forEach(key => {
			if(address[key] === undefined) {
				address[key] = '';
			}
		});
		return address;
	}

	componentDidUpdate(prevProps, prevState) {
		const { addresses, onChange, customer } = this.props;
		const { selectedAddress, newAddress } = this.state;
		if(prevProps.customer !== customer) {
			return this.setState(this.getDefaultState());
		}
		if(prevState.selectedAddress !== selectedAddress) {
			if(prevState.selectedAddress === 'new') {
				this.setState({ newAddress: prevState.address });
			}
			if(selectedAddress === 'new') {
				this.setState({ address: newAddress });
			} else if(customer && selectedAddress === 'self') {
				this.setState({ address: this.getSelf(customer) });
			} else {
				const address = addresses.find(({ code }) => code === selectedAddress);
				this.setState({ address });
			}
		}
		if(this.state.address !== prevState.address) {
			onChange(this.state.address);
		}
	}

	render() {
		const { selectedAddress } = this.state;
		const { addresses, countries, customer, user } = this.props;
		return <InputGroup parent={this} object="address">
			<FormGroup>
				<div className="input-group">
					<select className="form-control" value={selectedAddress} onChange={e => this.setState({ selectedAddress: e.target.value })}>
						{selectedAddress === 'new' && <option value="new">Nieuw</option>}
						{customer && <option value="self">Standaard</option>}
						{addresses && !!addresses.length && addresses.map(address =>
							<option key={address.code} value={address.code}>{address.adres}</option>
						)}
					</select>
					<InputGroupAddon addonType="append">
						<button className="btn btn-primary" onClick={e => {
							e.preventDefault();
							this.setState({ selectedAddress: 'new' });
						}}>Nieuw</button>
					</InputGroupAddon>
				</div>
			</FormGroup>
			<Row>
				<Col lg={12}>
					<FormGroup>
						<Input value="naam" placeholder="Naam" disabled={selectedAddress !== 'new'} />
					</FormGroup>
				</Col>
				<Col lg={6}>
					<FormGroup>
						<Input value="telefoon" type="tel" placeholder="Telefoonnummer" disabled={selectedAddress !== 'new'} />
					</FormGroup>
					<FormGroup>
						<Input value="adres" placeholder="Adres" disabled={selectedAddress !== 'new'} required />
					</FormGroup>
					<FormGroup>
						<Input value="postcode" placeholder="Postcode" disabled={selectedAddress !== 'new'} />
					</FormGroup>
				</Col>
				<Col lg={6}>
					<FormGroup>
						<Input value="stad" placeholder="Stad" disabled={selectedAddress !== 'new'} />
					</FormGroup>
					<FormGroup>
						<Input value="land" type="select" placeholder="Land" disabled={selectedAddress !== 'new'}>
							<option value="" disabled>Land</option>
							{countries.map(country =>
								<option value={country._id} key={country._id}>{country.naam}</option>)}
						</Input>
					</FormGroup>
					{customer && customer.klantNummer && user.roles.some(r => r === 'CRM' || r === 'SALESPERSON') && <FormGroup>
						<Link to={`/staff/customers/${customer.klantNummer}`}>Bewerken</Link>
					</FormGroup>}
				</Col>
			</Row>
		</InputGroup>;
	}
}

export default connect(store => {
	const { countries } = store.crm;
	const { user } = store.auth;
	return { countries, user };
})(AddressSelection);
