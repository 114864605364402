import React, { Fragment } from 'react';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import Pagination from '../Pagination';

export default class Orders extends React.Component {
	render() {
		const { orders, i18n, staff } = this.props;
		return <Pagination
			data={orders}
			perPage={20}
			filter={(query, order) => order.klant.naam.toLowerCase().includes(query) || order._id.toLowerCase().includes(query)
             || order.klant.klantNummer.toLowerCase().includes(query)
             || order.factuurNummer && order.factuurNummer.toLowerCase().includes(query)
             || order.regels && order.regels.some(line => line.code === query)}
			component={page =>
				<Table>
					<thead>
						<tr>
							<th>Code</th>
							{staff && <th>Klant</th>}
							<th>Totaal</th>
							<th>Land</th>
							<th>Datum</th>
							<th>Factuur</th>
							<th>Creditnota's</th>
						</tr>
					</thead>
					<tbody>
						{page.map(order =>
							<tr key={order._id}>
								<td><Link to={`${staff || ''}/orders/${order._id}`}>{order._id}</Link></td>
								{staff && <td>{order.klant.naam}</td>}
								<td>{i18n.format(order.prijs.euro.generaalTotaal)}</td>
								<td>{order.adres.land}</td>
								<td>{new Date(order.datum).toLocaleString()}</td>
								<td>{order.factuur ? <a href={`/api/invoice/${order.factuur}`} target="_blank">{order.factuurNummer}</a> : null}</td>
								<td>{(order.creditnotas || []).map(note => <Fragment key={note._id}>
									<a href={`/api/creditnote/${note._id}`} target="_blank">{note.code}</a>
									{' '}
								</Fragment>)}</td>
							</tr>
						)}
					</tbody>
				</Table>
			} />;
	}
}
