import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';

import webshopReducer from './webshop-reducer';
import navigationReducer from './navigation-reducer';
import authReducer from './auth-reducer';
import crmReducer from './crm-reducer';
import messageReducer from './message-reducer';

export default combineReducers({
	webshopState: webshopReducer,
	navigationState: navigationReducer,
	router: routerReducer,
	auth: authReducer,
	crm: crmReducer,
	messages: messageReducer,
});
