import React from 'react';
import { connect } from 'react-redux';
import HttpStatus from 'http-status-codes';
import store from '../store';
import { findCustomer, getShippingCosts } from '../api';
import dispatchMessage from './actions/message';
import { setCustomer, setCartShipping, setCartAddress, setVAT, setDeliveryWeeks, clearCart, setType, setSurcharge } from './actions/webshop';
import { getVolume } from '../components/webshop/Webshop';
const WAIT = 500;
const PERCENT = 100;

let timeout = 0;

function getCBM(item) {
	return getVolume(item.afmetingen);
}

class CartConnector extends React.Component {
	constructor(props) {
		super(props);
		this.getOrder = this.getOrder.bind(this);
		this.getPriceAndCbm = this.getPriceAndCbm.bind(this);
	}

	componentDidMount() {
		const { customerCode, address, user } = this.props;
		if(user && user.roles.includes('SALESPERSON')) {
			if(customerCode && (!this.props.customer || this.props.customer.klantNummer !== customerCode)) {
				findCustomer(customerCode).then(customers => {
					const customer = customers.find(c => c.klantNummer === customerCode);
					if(customer) {
						this.setCustomer(customer);
					}
				}).catch(err => {
					dispatchMessage('Failed to find customer', err);
				});
			}
			if(address && address.land) {
				this.getShippingCosts();
			}
		}
	}

	setCustomer(customer) {
		store.dispatch(setCustomer(customer));
	}

	setShipping(shipping) {
		store.dispatch(setCartShipping(shipping));
	}

	setAddress(address) {
		store.dispatch(setCartAddress(address));
	}

	setVAT(vat) {
		store.dispatch(setVAT(vat));
	}

	setWeeks(weeks) {
		store.dispatch(setDeliveryWeeks(weeks));
	}

	clearCart() {
		store.dispatch(clearCart());
	}

	setType(type) {
		store.dispatch(setType(type));
	}

	setSurcharge(surcharge) {
		store.dispatch(setSurcharge(surcharge));
	}

	getOrder(extras = {}) {
		const { cart, address, customer, type } = this.props;
		const order = cart.map(line => {
			return {
				code: line.item.artikelNummer,
				quantity: line.count,
				batches: line.batches,
				discount: line.discount,
				deliver: 'deliver' in line ? line.deliver : true,
			};
		});
		return Object.assign({ order, address, customer, type }, extras);
	}

	getShippingCosts() {
		clearTimeout(timeout);
		// this.setShipping('...');
		timeout = setTimeout(() => {
			getShippingCosts(this.getOrder()).then(({ prijs, btw, delivery, toeslag }) => {
				this.setVAT(btw);
				this.setShipping(prijs);
				this.setWeeks(delivery);
				this.setSurcharge(toeslag);
			}).catch(err => {
				if(err.response && err.response.status === HttpStatus.PRECONDITION_FAILED) {
					return dispatchMessage(err.response.data.message, err);
				}
				dispatchMessage('Failed to get shipping costs', err);
			});
		}, WAIT);
	}

	getPriceAndCbm() {
		const [totalPrice, cbm] = this.props.cart.reduce(([total, c], line) => {
			return [total + line.item.price * line.count * (1 - line.discount / PERCENT), c + line.item.afmetingen ? c + getCBM(line.item) * line.count : 0];
		}, [0, 0]);
		return { totalPrice, cbm };
	}

	componentDidUpdate(prevProps) {
		const { cart, address, type } = this.props;
		if(address !== prevProps.address || cart !== prevProps.cart || prevProps.type !== type) {
			if(!address.land) {
				this.setShipping('---');
			} else {
				this.getShippingCosts();
			}
		}
	}

	render() {
		const { customer, total, shipping, address, vat, cart, weeks, pass, user, type, surcharge } = this.props;
		const Component = this.props.component;
		return <Component {...pass}
			user={user}
			type={type}
			setCustomer={this.setCustomer}
			getOrder={this.getOrder}
			setAddress={this.setAddress}
			getPriceAndCbm={this.getPriceAndCbm}
			setType={this.setType}
			clearCart={this.clearCart}
			customer={customer}
			total={total}
			shipping={shipping}
			vat={vat}
			cart={cart}
			weeks={weeks}
			surcharge={surcharge}
			address={address} />;
	}
}

const Connected = connect(s => {
	const { user } = s.auth;
	const { customer, customerCode, total, shipping, address, vat, cart, weeks, type, surcharge } = s.webshopState;
	return { customer, customerCode, total, shipping, address, vat, cart, weeks, user, type, surcharge };
})(CartConnector);

export default component => {
	return props => <Connected pass={props} component={component} />;
};
