import {
	WEBSHOP_SET_SELECTED_TAB,
	WEBSHOP_UPDATE_CATEGORIES,
	WEBSHOP_UPDATE_ITEMS,
	WEBSHOP_UPDATE_PRODUCT_GROUPS,
	WEBSHOP_ADD_TO_CART,
	WEBSHOP_SET_CART,
	WEBSHOP_CLEAR_CART,
	WEBSHOP_UPDATE_ORDERS,
	WEBSHOP_SET_CUSTOMER,
	WEBSHOP_SET_CART_SHIPPING,
	WEBSHOP_SET_CART_ADDRESS,
	WEBSHOP_SET_VAT,
	WEBSHOP_SET_DELIVERY_WEEKS,
	WEBSHOP_SET_TYPE,
	WEBSHOP_SET_SURCHARGE,
} from './action-types/webshop';

import { loadFromSessionStorage, saveToSessionStorage } from '../util/storage';

const CART_KEY = 'WEBSHOP_CART';
const CART_CUSTOMER = 'WEBSHOP_CUSTOMER';
const CART_ADDRESS = 'WEBSHOP_ADDRESS';
const CART_TYPE = 'WEBSHOP_DELIVERY';

const emptyState = {
	categories: [],
	selectedTab: null,
	items: [],
	productGroups: [],
	cart: [],
	orders: [],
	customer: null,
	customerCode: null,
	shipping: '---',
	address: {},
	vat: 21,
	weeks: null,
	type: null,
	surcharge: 0,
};

const initialState = Object.assign({}, {
	cart: loadFromSessionStorage(CART_KEY, true, []),
	customerCode: loadFromSessionStorage(CART_CUSTOMER, false, null),
	address: loadFromSessionStorage(CART_ADDRESS, true, {}),
	type: loadFromSessionStorage(CART_TYPE, true, null),
});

function isSameBatch(lhs, rhs) {
	return lhs.length > 1 && rhs.length > 1 || (lhs === rhs || lhs[0] === rhs[0] || lhs[0].batch === rhs[0].batch);
}

function getIndex(cart, payload) {
	return cart.findIndex(line =>
		line.locatie === payload.locatie
        && line.item.artikelNummer === payload.item.artikelNummer
        && isSameBatch(line.batches, payload.batches));
}

export function getInCart(cart, payload) {
	const index = getIndex(cart, payload);
	if(index < 0) {
		return 0;
	}
	return cart[index].count;
}

function mapCart(cart, payload, count) {
	const index = getIndex(cart, payload);
	if(index < 0) {
		const added = {
			item: payload.item,
			count: count(0),
			batches: payload.batches,
			discount: payload.discount || 0,
			locatie: payload.locatie,
			deliver: payload.deliver,
		};
		if(added.count < 1) {
			return cart;
		}
		return cart.concat([added]);
	}
	const line = cart[index];
	const added = {
		item: line.item,
		count: count(line.count),
		batches: payload.batches,
		discount: payload.discount !== undefined ? payload.discount : line.discount,
		locatie: payload.locatie,
		deliver: payload.deliver !== undefined ? payload.deliver : line.deliver,
	};
	const output = cart.slice(0, index);
	if(added.count > 0) {
		output.push(added);
	}
	return output.concat(cart.slice(index + 1));
}

const reducer = (state = initialState, action) => {
	if (action.type === WEBSHOP_UPDATE_CATEGORIES) {
		return Object.assign({}, state, { categories: action.payload.categories });
	} else if (action.type === WEBSHOP_SET_SELECTED_TAB) {
		return Object.assign({}, state, { selectedTab: action.payload.tab });
	} else if (action.type === WEBSHOP_UPDATE_ITEMS) {
		return Object.assign({}, state, { items: action.payload.items });
	} else if(action.type === WEBSHOP_UPDATE_PRODUCT_GROUPS) {
		return Object.assign({}, state, { productGroups: action.payload.productGroups });
	} else if(action.type === WEBSHOP_UPDATE_ORDERS) {
		return Object.assign({}, state, { orders: action.payload.orders });
	} else if (action.type === WEBSHOP_ADD_TO_CART) {
		const cart = mapCart(state.cart, action.payload, count => count + 1);
		saveToSessionStorage(CART_KEY, cart);
		return Object.assign({}, state, { cart });
	} else if(action.type === WEBSHOP_SET_CART) {
		const { quantity } = action.payload;
		const cart = mapCart(state.cart, action.payload, () => quantity);
		saveToSessionStorage(CART_KEY, cart);
		return Object.assign({}, state, { cart });
	} else if(action.type === WEBSHOP_CLEAR_CART) {
		const cart = [];
		saveToSessionStorage(CART_KEY, cart);
		return Object.assign({}, state, { cart });
	} else if(action.type === WEBSHOP_SET_CUSTOMER) {
		const customer = action.payload;
		const customerCode = customer && customer.klantNummer;
		saveToSessionStorage(CART_CUSTOMER, customerCode, false);
		const replacement = { customer, customerCode };
		if(!customer) {
			replacement.vat = emptyState.vat;
			replacement.address = {};
			replacement.shipping = emptyState.shipping;
			replacement.weeks = emptyState.weeks;
			replacement.surcharge = emptyState.surcharge;
			saveToSessionStorage(CART_ADDRESS, {});
		}
		return Object.assign({}, state, replacement);
	} else if(action.type === WEBSHOP_SET_CART_SHIPPING) {
		const shipping = action.payload;
		return Object.assign({}, state, { shipping });
	} else if(action.type === WEBSHOP_SET_CART_ADDRESS) {
		const address = action.payload;
		saveToSessionStorage(CART_ADDRESS, address);
		return Object.assign({}, state, { address });
	} else if(action.type === WEBSHOP_SET_VAT) {
		const vat = action.payload;
		return Object.assign({}, state, { vat });
	} else if(action.type === WEBSHOP_SET_DELIVERY_WEEKS) {
		const weeks = action.payload;
		return Object.assign({}, state, { weeks });
	} else if(action.type === 'reset') {
		saveToSessionStorage(CART_ADDRESS, {});
		saveToSessionStorage(CART_CUSTOMER, null, false);
		saveToSessionStorage(CART_KEY, []);
		saveToSessionStorage(CART_TYPE, null);
		return emptyState;
	} else if(action.type === WEBSHOP_SET_TYPE) {
		const type = action.payload;
		saveToSessionStorage(CART_TYPE, type);
		return Object.assign({}, state, { type });
	} else if(action.type === WEBSHOP_SET_SURCHARGE) {
		const surcharge = action.payload;
		return Object.assign({}, state, { surcharge });
	}
	return state;
};

export default reducer;
