import React from 'react';
import { Table, Form } from 'reactstrap';
import { Link } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import Address from './Address';
import AddressSelection from './AddressSelection';
import { updateOrder, createInvoice, saveOrder, removeShipping, sendInvoice, createNavInvoice, createNavCreditNote } from '../../api';
import dispatchMessage from '../../reducers/actions/message';

export default class Order extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			address: props.order ? props.order.adres : null,
			submitting: false,
			invoice: props.order ? props.order.factuur : null,
		};
		this.updateAddress = this.updateAddress.bind(this);
		this.createInvoice = this.createInvoice.bind(this);
		this.saveOrder = this.saveOrder.bind(this);
		this.removeShipping = this.removeShipping.bind(this);
		this.sendInvoice = this.sendInvoice.bind(this);
		this.navInvoice = this.navInvoice.bind(this);
	}

	componentWillReceiveProps(props) {
		if(props.order && !this.props.order) {
			this.setState({
				invoice: props.order.factuur,
				address: props.order.adres,
			});
		}
	}

	updateAddress(e) {
		e.preventDefault();
		const { address } = this.state;
		const { order, update } = this.props;
		this.setState({ submitting: true });
		updateOrder(order, address).then(() => {
			update();
		}).catch(err => {
			dispatchMessage('Failed to update order', err);
		}).then(() => {
			this.setState({ submitting: false });
		});
	}

	createInvoice() {
		this.setState({ submitting: true });
		createInvoice(this.props.order).then(invoice => {
			this.setState({ invoice });
		}).catch(err => {
			dispatchMessage('Failed to create invoice', err);
		}).then(() => {
			this.setState({ submitting: false });
		});
	}

	saveOrder() {
		const { order, update } = this.props;
		this.setState({ submitting: true });
		saveOrder(order, { delivered: true }).then(() => {
			update();
		}).catch(err => {
			dispatchMessage('Failed to deliver order', err);
		}).then(() => {
			this.setState({ submitting: false });
		});
	}

	removeShipping(e) {
		e.preventDefault();
		const { order, update } = this.props;
		this.setState({ submitting: true });
		removeShipping(order).then(() => {
			update();
		}).catch(err => {
			dispatchMessage('Failed to remove shipping', err);
		}).then(() => {
			this.setState({ submitting: false });
		});
	}

	sendInvoice(e) {
		e.preventDefault();
		const { order } = this.props;
		this.setState({ submitting: true });
		sendInvoice(order._id).then(() => {
			window.alert('Verstuurd');
		}).catch(err => {
			dispatchMessage('Failed to send invoice', err);
		}).then(() => {
			this.setState({ submitting: false });
		});
	}

	navInvoice(e) {
		e.preventDefault();
		const { order, update } = this.props;
		this.setState({ submitting: true });
		createNavInvoice(order._id).then(() => {
			update();
			window.alert('Aangemaakt');
		}).catch(err => {
			dispatchMessage('Failed to create invoice', err);
		}).then(() => {
			this.setState({ submitting: false });
		});
	}

	navCredit(note) {
		return e => {
			e.preventDefault();
			const { order, update } = this.props;
			this.setState({ submitting: true });
			createNavCreditNote(order._id, note._id).then(() => {
				update();
				window.alert('Aangemaakt');
			}).catch(err => {
				dispatchMessage('Failed to create credit note', err);
			}).then(() => {
				this.setState({ submitting: false });
			});
		};
	}

	render() {
		const { order, i18n, customer, user } = this.props;
		if(!order) {
			return null;
		}
		const { address, invoice, submitting } = this.state;
		const isSales = user.roles.includes('SALES');
		const isAdmin = user.roles.includes('ADMIN');
		return <section className="container">
			<h2>{order._id}</h2>
			<p>{new Date(order.datum).toLocaleString()}</p>
			{order.referentie && <p>Referentie: {order.referentie}</p>}
			{!user.roles.includes('CUSTOMER') && customer && <p>{customer.navCode || customer.klantNummer} {customer.naam}</p>}
			<Table>
				<thead>
					<tr>
						<th>Code</th>
						<th>Naam</th>
						<th className="text-right">Stukprijs</th>
						<th className="text-right">Stuks</th>
						<th className="text-right">Korting %</th>
						<th className="text-right">Prijs</th>
					</tr>
				</thead>
				<tbody>
					{order.regels.map((line, i) =>
						<tr key={i}>
							<td>
								<img src={`https://api.ptmd.nl/rest/images/v1/small/${line.code}.jpg`} className="item-image" /><br />
								{line.code}
							</td>
							<td>{i18n.select((line.items[0] || {}).naam || {})}</td>
							<td className="text-right">{i18n.format(line.prijs.stuk)}</td>
							<td className="text-right">{line.quantity}</td>
							<td className="text-right">{line.prijs.korting.percentage}</td>
							<td className="text-right">{i18n.format(line.prijs.totaal)}</td>
						</tr>
					)}
					<tr className="small">
						<td colSpan={5} className="text-right"><strong>Totaal</strong></td>
						<td className="text-right">{i18n.format(order.prijs.euro.subtotaal)}</td>
					</tr>
					<tr className="small">
						<td colSpan={5} className="text-right"><strong>Korting</strong></td>
						<td className="text-right">{i18n.format(order.prijs.euro.korting)}</td>
					</tr>
					{'aanbetaling' in order && <tr className="small">
						<td colSpan={5} className="text-right"><strong>Aanbetaling</strong></td>
						<td className="text-right">{i18n.format(order.aanbetaling)}</td>
					</tr>}
					<tr className="small">
						<td colSpan={5} className="text-right"><strong>Verzendkosten</strong></td>
						<td className="text-right">{i18n.format(order.prijs.euro.transport) || '---'}</td>
					</tr>
					<tr className="small">
						<td colSpan={5} className="text-right"><strong>Totaal excl. btw</strong></td>
						<td className="text-right">{i18n.format(order.prijs.euro.totaal - (order.aanbetaling || 0))}</td>
					</tr>
					<tr className="small">
						<td colSpan={5} className="text-right"><strong>BTW</strong></td>
						<td className="text-right">{i18n.format(order.prijs.euro.btw)}</td>
					</tr>
					{order.prijs.euro.toeslag > 0 && <tr className="small">
						<td colSpan={5} className="text-right"><strong>Toeslag</strong></td>
						<td className="text-right">{i18n.format(order.prijs.euro.toeslag)}</td>
					</tr>}
					<tr>
						<td colSpan={5} className="text-right"><strong>Totaal incl. btw</strong></td>
						<td className="text-right">{i18n.format(order.prijs.euro.generaalTotaal - (order.aanbetaling || 0))}</td>
					</tr>
				</tbody>
			</Table>
			<h2>Leveradres</h2>
			{invoice ? <Address adres={order.adres} /> : <Form onSubmit={this.updateAddress}>
				<AddressSelection
					onChange={a => this.setState({ address: a })}
					addresses={customer && customer.adressen}
					customer={customer}
					address={address} />
				{address && address.code !== order.adres.code &&
                  <input type="submit" className="ptmd-button" value="Update" disabled={submitting} />}
			</Form>}
			{order.bevestiging && <p><a href={`/api/confirmation/${order.bevestiging}`}>Bekijk bevestiging</a></p>}
			{order.uitgeleverd ? <p>Uitgeleverd op {order.uitgeleverd}</p> : isSales && <p>
				<button className="ptmd-button" disabled={submitting} onClick={this.saveOrder}>Uitgeleverd</button>
			</p>}
			{invoice ? <p>
				<a href={`/api/invoice/${invoice}`}>Bekijk factuur</a>
				{isAdmin && <button className="ptmd-button" onClick={this.sendInvoice} disabled={submitting}>Verstuur factuur</button>}
				{isAdmin && !order.factuurInNav && <button className="ptmd-button" onClick={this.navInvoice} disabled={submitting}>Stuur factuur naar Navision</button>}
				{isSales && <Link className="ptmd-button" to={`/staff/orders/${order._id}/credit`} disabled={submitting}>Crediteer</Link>}
			</p> :
				isSales && <button
					className="ptmd-button"
					onClick={this.createInvoice}
					disabled={submitting}
				>Genereer Factuur</button>
			}
			{(order.creditnotas || []).map(note => <div className="row" key={note._id}>
				<div className="col-md-8">
					<a href={`/api/creditnote/${note._id}`}>Bekijk creditnota {note.code}</a>
					{isSales && <p>{note.reason}</p>}
				</div>
				{isSales && !note.inNav && <div className="col-md-4">
					<button disabled={submitting} className="ptmd-button" onClick={this.navCredit(note)}>Stuur naar Navision</button>
				</div>}
			</div>)}
			{isSales && !invoice && <button className="ptmd-button red-button" onClick={this.removeShipping} disabled={submitting}>Verwijder transportkosten</button>}
		</section>;
	}
}
