import CreateCurrency from './CreateCurrency';
import { getCurrency } from '../../api';
import dispatchMessage from '../../reducers/actions/message';

export default class EditCurrency extends CreateCurrency {
	constructor(props) {
		super(props);
		this.state.currency = null;
		this.state.edit = true;
		this.updateCurrency(props);
	}

	componentWillReceiveProps(props) {
		this.updateCurrency(props);
	}

	updateCurrency(props) {
		const { id } = props.match.params;
		if(!this.state.currency || id !== this.state.currency._id) {
			getCurrency(id).then(currency => {
				this.setState({ currency });
			}).catch(err => {
				dispatchMessage('Failed to get currency', err);
			});
		}
	}

	render() {
		const { currency } = this.state;
		if(!currency) {
			return null;
		}
		return super.render();
	}
}
