import React from 'react';
import { connect } from 'react-redux';
import { getAllOrders } from '../../api';
import Orders from '../../components/orders/Orders';

class FacturenContainer extends React.Component {
	componentDidMount() {
		getAllOrders(true);
	}

	render() {
		const { orders, i18n } = this.props;
		return <section className="container">
			<h2>Orders</h2>
			<Orders orders={orders} i18n={i18n} staff="/staff" />
		</section>;
	}
}

export default connect(store => {
	const { orders } = store.webshopState;
	return { orders };
})(FacturenContainer);
