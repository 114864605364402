import React, { Fragment } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';

export default class AuditContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			start: moment().startOf('month'),
			end: moment().endOf('day'),
		};
	}

	render() {
		const { roles } = this.props.user;
		const { start, end } = this.state;
		return <section className="container">
			<h2>Rapportage</h2>
			{roles.includes('SALES') && <a href="/api/staff/stats/invoices" className="ptmd-button">Exporteer facturen</a>}
			<a href="/api/staff/stats/items/stock" className="ptmd-button">Voorraad</a>
			{roles.includes('STOCK') && <Fragment>
				<p>
					<DatePicker
						className="form-control"
						selected={start}
						dateFormat="YYYY/MM/DD"
						maxDate={end}
						onChange={date => this.setState({ start: date })}
					/>{' '}
					<DatePicker
						className="form-control"
						selected={end}
						dateFormat="YYYY/MM/DD"
						minData={start}
						onChange={date => this.setState({ end: date })}
					/>
				</p>
				<p>
					<a href={`/api/staff/audit/picked/${start.toISOString()}/${end.toISOString()}`}>Gepakte orders</a>
				</p>
				<p>
					<a href={`/api/staff/audit/itemmutations/${start.toISOString()}/${end.toISOString()}`}>Verplaatste items</a>
				</p>
				<p>
					<a href={`/api/staff/audit/newitems/${start.toISOString()}/${end.toISOString()}`}>Opboekingen</a>
				</p>
				<p>
					<a href={`/api/staff/audit/deleteditems/${start.toISOString()}/${end.toISOString()}`}>Afboekingen</a>
				</p>
				<p>
					<a href={`/api/staff/audit/sold/${start.toISOString()}/${end.toISOString()}`}>Verkocht op artikelniveau</a>
				</p>
				<p>
					<a href={`/api/staff/audit/navsent/${start.toISOString()}/${end.toISOString()}`}>Factuur/creditnota fouten</a>
				</p>
			</Fragment>}
		</section>;
	}
}
