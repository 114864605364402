const LANGUAGE_LOCALES = {
	DEU: {
		locales: ['de'],
		name: 'Deutsch',
	},
	ENU: {
		locales: ['en-GB', 'en'],
		name: 'English',
	},
	ESP: {
		locales: ['es-ES', 'es'],
		name: 'Español',
	},
	FRA: {
		locales: ['fr-FR', 'fr'],
		name: 'Français',
	},
	NLD: {
		locales: ['nl-NL', 'nl'],
		name: 'Nederlands',
	},
	ITA: {
		locales: ['it-IT', 'it'],
		name: 'Italiano',
	},
	// NOR: {
	//     locales: ['no-NO', 'no'],
	//     name: 'Norsk',
	// },
	// DAN: {
	//     locales: ['dk-DK', 'dk'],
	//     name: 'Dansk',
	// },
};
const TAG_LENGTH = 2;

export const LANGUAGES = Object.keys(LANGUAGE_LOCALES).map(code => ({
	code,
	name: LANGUAGE_LOCALES[code].name,
}));

export const DEFAULT_LANGUAGE = 'ENU';

export function getLocales(languageCode) {
	let locales = [];
	if(languageCode && LANGUAGE_LOCALES[languageCode]) {
		locales = locales.concat(LANGUAGE_LOCALES[languageCode].locales);
	}
	if(window.navigator) {
		const { languages, language } = window.navigator;
		if(languages) {
			locales = locales.concat(languages);
		} else if(language) {
			locales.push(language);
		}
	}
	locales.push('en');
	return locales;
}

export function getLanguage(locales) {
	for(const locale of locales) {
		for(const language in LANGUAGE_LOCALES) {
			const short = locale.slice(0, TAG_LENGTH);
			if(LANGUAGE_LOCALES[language].locales.some(val => val === locale || val === short)) {
				return language;
			}
		}
	}
	return DEFAULT_LANGUAGE;
}

export function isLanguage(code) {
	return code in LANGUAGE_LOCALES;
}
