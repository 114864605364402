import React, { Fragment } from 'react';

function filter(line) {
	const out = line.filter(e => e);
	while(out.length && typeof out[0] !== 'string') {
		out.shift();
	}
	while(out.length && typeof out[out.length - 1] !== 'string') {
		out.pop();
	}
	return out;
}

export default class Address extends React.Component {
	render() {
		const { naam, naam2, adres, adres2, postcode, stad, land, telefoon, email } = this.props.adres;
		const lines = [
			[naam, <br />, naam2],
			[adres, <br />, adres2],
			[postcode, <br />, stad, ' ', land],
			[telefoon ? `Tel: ${telefoon}` : telefoon, <br />, email],
		].map(filter).filter(line => line.length);
		return <div className="address">
			{lines.map((line, i) => <p key={i}>{line.map((l, j) => <Fragment key={j}>{l}</Fragment>)}</p>)}
		</div>;
	}
}
