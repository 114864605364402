import $ from 'jquery';

const scrollAnimationTime = 500;


export function scrollTo(selector, topMarginSelector = null) {
	const $element = $(selector);
	let topOffset = $element.offset().top;

	if (topMarginSelector) {
		const $topMarginElement = $(topMarginSelector);

		if ($topMarginElement) {
			topOffset -= $topMarginElement.height();
		}
	}

	$('html, body').animate({
		scrollTop: topOffset,
	}, scrollAnimationTime);
}

export function scrollToTop() {
	$('html, body').scrollTop(0);
}
