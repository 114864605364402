import React from 'react';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { getUnsentOrders } from '../../api';
import dispatchMessage from '../../reducers/actions/message';
import Pagination from '../../components/Pagination';

export default class UitleverContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			orders: [],
			selected: {},
		};
		this.checkAll = this.checkAll.bind(this);
	}

	componentDidMount() {
		getUnsentOrders().then(orders => {
			this.setState({ orders, selected: {} });
		}).catch(err => {
			dispatchMessage('Failed to get orders', err);
		});
	}

	check(id) {
		return e => {
			this.setState({
				selected: Object.assign({}, this.state.selected, {
					[id]: e.target.checked,
				}),
			});
		};
	}

	checkAll(e) {
		const selected = {};
		this.state.orders.forEach(({ _id }) => {
			selected[_id] = e.target.checked;
		});
		this.setState({ selected });
	}

	render() {
		const { i18n, history } = this.props;
		const { orders, selected } = this.state;
		const anySelected = Object.keys(selected).every(key => !selected[key]);
		return <section className="container">
			<h2>Niet uitgeleverde orders</h2>
			<button
				className="ptmd-button"
				onClick={e => {
					e.preventDefault();
					history.push(`/staff/uitleveren/orders?${queryString.stringify({ ids: Object.keys(selected).filter(key => selected[key]) })}`);
				}}
				disabled={anySelected}>
                Maak picklijst/stickers
			</button>
			<Pagination
				data={orders}
				perPage={20}
				filter={(query, order) => order.klant.naam.toLowerCase().includes(query) || order._id.toLowerCase().includes(query)
                 || order.klant.klantNummer.toLowerCase().includes(query)}
				component={page =>
					<Table>
						<thead>
							<tr>
								<th><input type="checkbox" onChange={this.checkAll} /></th>
								<th>Code</th>
								<th>Klant</th>
								<th>Totaal</th>
								<th>Land</th>
								<th>Datum</th>
								<th>Ingeladen</th>
								<th>CBM</th>
							</tr>
						</thead>
						<tbody>
							{page.map(order =>
								<tr key={order._id}>
									<td><input type="checkbox" checked={selected[order._id] || false} onChange={this.check(order._id)} /></td>
									<td><Link to={`/staff/uitleveren/orders/${order._id}`}>{order._id}</Link></td>
									<td>{order.klant.naam}</td>
									<td>{i18n.format(order.prijs.euro.generaalTotaal)}</td>
									<td>{order.adres.land}</td>
									<td>{new Date(order.datum).toLocaleString()}</td>
									<td><input type="checkbox" disabled checked={!!order.ingeladen} /></td>
									<td>{order.cbm}</td>
								</tr>
							)}
						</tbody>
					</Table>
				} />
		</section>;
	}
}
