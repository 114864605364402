import React from 'react';
import { Link } from 'react-router-dom';
import Currencies from './Currencies';

export default class StamContainer extends React.Component {
	render() {
		return <div>
			<h2>Valuta</h2>
			<Link to="/staff/currencies/new" className="ptmd-button green-button">Nieuw</Link>
			<Currencies />
		</div>;
	}
}
