import React from 'react';
import Items from './Items';

export default function ItemContainer({ i18n, user }) {
	return <section className="container">
		<Items
			user={user}
			i18n={i18n}
			filter={(query, item) => item.artikelNummer.toLowerCase().includes(query)
              || item.quantity.some(({ locatie }) => locatie && locatie.toLowerCase() === query)} />
	</section>;
}
