import {createStore, applyMiddleware} from 'redux';
import {routerMiddleware} from 'react-router-redux';

import reducers from './reducers/reducers';
import history from './history';

import {scrollToTopMW} from './middleware';

const routerMW = routerMiddleware(history);

const store = createStore(reducers, applyMiddleware(routerMW, scrollToTopMW));

export default store;
