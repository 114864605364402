import React from 'react';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import DatePicker from 'react-datepicker';
import { Row, Col } from 'reactstrap';
import { getBestSellers } from '../../api';
import dispatchMessage from '../../reducers/actions/message';

const DELAY = 500;

export default class StatsContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			items: [],
			start: moment().startOf('month'),
			end: moment(),
		};
		this.timeout = 0;
		this.updateStats = this.updateStats.bind(this);
	}

	componentDidMount() {
		this.updateStats();
	}

	updateStats() {
		const { start, end } = this.state;
		getBestSellers(start.toISOString(), end.toISOString()).then(items => {
			this.setState({ items });
		}).catch(err => {
			dispatchMessage('Failed to get stats', err);
		});
	}

	componentDidUpdate(props, state) {
		if(this.state.start !== state.start || this.state.end !== state.end) {
			clearTimeout(this.timeout);
			this.timeout = setTimeout(this.updateStats, DELAY);
		}
	}

	render() {
		const { start, end, items } = this.state;
		return <section className="container">
			<h2>Stats</h2>
			<Bar data={{
				labels: items.map(i => i._id),
				datasets: [ {
					label: '',
					data: items.map(i => i.quantity ),
				} ],
			}} />
			<Row>
				<Col md={6}>
					<DatePicker
						className="form-control"
						selected={start}
						dateFormat="YYYY/MM/DD"
						maxDate={end}
						onChange={date => this.setState({ start: date })}
					/>
				</Col>
				<Col md={6}>
					<DatePicker
						className="form-control"
						selected={end}
						dateFormat="YYYY/MM/DD"
						minData={start}
						onChange={date => this.setState({ end: date })}
					/>
				</Col>
			</Row>
		</section>;
	}
}
