import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Items from './Items';
import ItemFilters from './ItemFilters';
import {Col, Row} from 'reactstrap';
import SingleItem from './SingleItem';

function compare(lhs, rhs) {
	lhs = lhs && lhs.toLowerCase();
	rhs = rhs && rhs.toLowerCase();
	if(lhs < rhs) {
		return -1;
	} else if(lhs > rhs) {
		return 1;
	}
	return 0;
}

const SORT_OPTIONS = {
	Aanbevolen: i18n => (lhs, rhs) =>
		compare(lhs.marketing.productGroep, rhs.marketing.productGroep)
        || compare(i18n.select(lhs.naam), i18n.select(rhs.naam)),
	'Prijs (Laag - Hoog)': () => (lhs, rhs) => lhs.price - rhs.price,
	'Prijs (Hoog - Laag)': () => (lhs, rhs) => rhs.price - lhs.price,
	'Naam (A - Z)': i18n => (lhs, rhs) =>
		compare(i18n.select(lhs.naam), i18n.select(rhs.naam)),
	'Naam (Z - A)': i18n => (lhs, rhs) =>
		compare(i18n.select(rhs.naam), i18n.select(lhs.naam)),
};

const SEARCH_TIMEOUT = 500;
const CUBIC_CM = 1e6;
const ROUNDING = 1e3;

export function getSize(size) {
	if(!size) {
		return '';
	}
	const {height, width, length} = size;
	return `${height} x ${width} x ${length} cm`;
}

export function getVolume(afmetingen) {
	const { item, outerbox } = afmetingen;
	if(item) {
		if(item.cbm > 0) {
			return item.cbm;
		}
		const cm = item.height * item.width * item.length;
		if(cm > 0) {
			return Math.round(cm / CUBIC_CM * ROUNDING) / ROUNDING;
		} else if(outerbox.cbm > 0) {
			return outerbox.cbm;
		}
	} else if(!outerbox) {
		return 0;
	}
	return Math.round(outerbox.height * outerbox.width * outerbox.length * ROUNDING / CUBIC_CM) / ROUNDING;
}

export default class Webshop extends React.Component {
	static get propTypes() {
		return {
			productGroups: PropTypes.array.isRequired,
			imageBaseUrl: PropTypes.string.isRequired,
			items: PropTypes.array.isRequired,
		};
	}

	constructor(props) {
		super(props);
		this.state = {
			productGroups: this.filterProductGroups(props),
			imageBaseUrl: props.imageBaseUrl,
			availableTabs: [
				'items',
				'groups',
				'best',
			],
			items: props.items,
			maxPrice: this.getMaxPrice(props.items),
			filters: {
				minPrice: 0,
				maxPrice: Infinity,
				sort: 'Aanbevolen',
				query: '',
			},
		};
		this.searchTimeout = 0;
		this.setFilters = this.setFilters.bind(this);
		this.filterItems = this.filterItems.bind(this);
		this.getItems = this.getItems.bind(this);
		this.getBreadcrumbs = this.getBreadcrumbs.bind(this);
	}

	componentWillReceiveProps(props) {
		this.setState({
			productGroups: this.filterProductGroups(props),
			items: props.items,
			maxPrice: this.getMaxPrice(props.items),
		});
	}

	filterProductGroups(props) {
		const { productGroups, items } = props;
		if(!productGroups.length || !items.length) {
			return productGroups;
		}
		return productGroups.filter(group => items.some(item => item.marketing.productGroep === group.code));
	}

	getMaxPrice(items) {
		return items.reduce((max, item) => Math.max(max, item.price), 0);
	}

	setFilters(filters) {
		clearTimeout(this.searchTimeout);
		this.searchTimeout = setTimeout(() => {
			this.setState({
				filters: Object.assign({}, this.state.filters, filters),
			});
		}, SEARCH_TIMEOUT);
	}

	itemContains(item, query) {
		const { i18n } = this.props;
		return [
			item.artikelNummer,
			i18n.select(item.naam),
			i18n.select(item.omschrijving),
			item.marketing.collectie,
		].some(str => str && str.toLowerCase().includes(query));
	}

	filterItems(props) {
		const { items, productGroupCode, filters, sort } = props;
		const query = filters.query.trim().toLowerCase();
		if(!query) {
			return [];
		}
		const out = items.filter(item => {
			if(productGroupCode && item.marketing.productGroep !== productGroupCode
            || query && !this.itemContains(item, query)) {
				return false;
			}
			return item.price >= filters.minPrice && item.price <= filters.maxPrice;
		});
		if(sort) {
			return out.sort(sort(this.props.i18n));
		}
		return out;
	}

	getItems() {
		const {productGroupCode} = this.props;
		const {items, filters} = this.state;

		return this.filterItems({
			items,
			productGroupCode,
			filters,
			sort: SORT_OPTIONS[filters.sort],
		});
	}

	getBreadcrumbs(state, props) {
		const { i18n } = this.props;
		const { productGroup, items, selectedItem } = props;
		const inSingleItem = state === 'single';
		const inItemList = state === 'multi';
		const pathSections = [''];
		const breadcrumbs = [];

		if (productGroup) {
			if (selectedItem && inSingleItem) {
				const path = pathSections.concat([ productGroup.code ]).join('/');
				breadcrumbs.push(<Link key="product-group" to={path}>{i18n.select(productGroup)}</Link>);
			} else {
				breadcrumbs.push(i18n.select(productGroup));
			}
		}

		if (selectedItem && inSingleItem) {
			breadcrumbs.push(<span key="separator-2" className="separator">/</span>);
			breadcrumbs.push(this.props.i18n.select(selectedItem.naam));
		}

		return (
			<h3 className="breadcrumb">
				{breadcrumbs}

				{inItemList && <span className="d-inline-block ml-auto">{items.length} Resultaten</span>}
			</h3>
		);
	}

	render() {
		const { productGroupCode, productCode, i18n, cart, user } = this.props;
		const productGroup = this.state.productGroups.find(group => group.code === productGroupCode);
		const showSingleItem = productGroup && productCode;
		const selectedItem = showSingleItem && this.state.items.find(item => item.artikelNummer === productCode);
		const items = this.getItems();
		const breadcrumbsProps = {
			items,
			productGroup,
			selectedItem,
		};
		const cols = {
			filters: {
				xs: 12,
			},
			items: {
				xs: 12,
			},
		};

		return (
			<section id="webshop" className="container">
				{showSingleItem && selectedItem && <Fragment>
					{this.getBreadcrumbs('single', breadcrumbsProps)}
					<SingleItem
						item={selectedItem}
						productGroup={productGroup}
						i18n={i18n}
						cart={cart}
						user={user}
					/>
				</Fragment>
				}

				{showSingleItem && !selectedItem &&
                    <h3>Loading item...</h3>
				}

				{!showSingleItem && <Fragment>
					<h2><img alt="Unique Vintage Floor" src="/images/uvf.png" /></h2>
					<Row>
						<Col {...cols.filters}>
							<ItemFilters
								categories={this.state.categories}
								productGroups={this.state.productGroups}
								maxPrice={this.state.maxPrice}
								setFilters={this.setFilters}
								i18n={i18n}
								sortOptions={SORT_OPTIONS}
								searchOnly
							/>
						</Col>
						<Col {...cols.items}>
							<Items
								productGroupCode={productGroupCode}
								items={items}
								filters={this.state.filters}
								sort={SORT_OPTIONS[this.state.filters.sort]}
								i18n={i18n}
								noQuery={!this.state.filters.query.trim()}
							/>
						</Col>
					</Row>
				</Fragment>}
			</section>
		);
	}
}
