import React from 'react';
import { Table, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getPickOrders, createOrderExport, uploadOrderExport } from '../../api';
import dispatchMessage from '../../reducers/actions/message';
import Pagination from '../../components/Pagination';

export default class UitleverContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			orders: [],
			selected: {},
		};
		this.checkAll = this.checkAll.bind(this);
		this.create = this.create.bind(this);
		this.upload = this.upload.bind(this);
	}

	componentDidMount() {
		getPickOrders().then(orders => {
			this.setState({ orders, selected: {} });
		}).catch(err => {
			dispatchMessage('Failed to get orders', err);
		});
	}

	check(id) {
		return e => {
			this.setState({
				selected: Object.assign({}, this.state.selected, {
					[id]: e.target.checked,
				}),
			});
		};
	}

	checkAll(e) {
		const selected = {};
		this.state.orders.forEach(({ _id, ingeladen }) => {
			if(ingeladen) {
				selected[_id] = e.target.checked;
			}
		});
		this.setState({ selected });
	}

	downloadFile(uri) {
		const link = document.createElement('a');
		if(typeof link.download === 'string') {
			link.href = uri;
			link.download = `${Date.now()}.csv`;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} else {
			window.location = uri;
		}
	}

	create() {
		const { selected } = this.state;
		const ids = Object.keys(selected).filter(key => selected[key]);
		createOrderExport(ids).then(file => {
			const reader = new FileReader();
			reader.onload = e => {
				this.downloadFile(e.target.result);
			};
			reader.readAsDataURL(new Blob([file], { type: 'text/csv' }));
		}).catch(err => {
			dispatchMessage('Failed to create file', err);
		});
	}

	upload() {
		const { selected } = this.state;
		const ids = Object.keys(selected).filter(key => selected[key]);
		uploadOrderExport(ids).then(() => {
			alert('Bestand geüpload');
		}).catch(err => {
			dispatchMessage('Failed to upload file', err);
		});
	}

	render() {
		const { orders, selected } = this.state;
		const anySelected = Object.keys(selected).every(key => !selected[key]);
		return <section className="container">
			<h2>Niet uitgeleverde orders</h2>
			<Row>
				<Col md={6}>
					<button
						className="ptmd-button"
						onClick={this.create}
						disabled={anySelected}>
                        Maak uitslagbestand
					</button>
				</Col>
				<Col md={6}>
					<button
						className="ptmd-button"
						onClick={this.upload}
						disabled={anySelected}>
                        Upload aanmelding naar Jan Krediet
					</button>
				</Col>
			</Row>
			<Pagination
				data={orders}
				perPage={20}
				filter={(query, order) => order.klant.naam.toLowerCase().includes(query) || order._id.toLowerCase().includes(query)
                 || order.klant.klantNummer.toLowerCase().includes(query)}
				component={page =>
					<Table>
						<thead>
							<tr>
								<th><input type="checkbox" onChange={this.checkAll} /></th>
								<th>Code</th>
								<th>Klant</th>
								<th>Land</th>
								<th>Ingeladen</th>
								<th>CBM</th>
							</tr>
						</thead>
						<tbody>
							{page.map(order =>
								<tr key={order._id}>
									<td><input type="checkbox" checked={selected[order._id] || false} onChange={this.check(order._id)} disabled={!order.ingeladen} /></td>
									<td><Link to={`/staff/pick/orders/${order._id}`}>{order._id}</Link></td>
									<td>{order.klant.naam}</td>
									<td>{order.adres.land}</td>
									<td><input type="checkbox" disabled checked={!!order.ingeladen} /></td>
									<td>{order.cbm}</td>
								</tr>
							)}
						</tbody>
					</Table>
				} />
		</section>;
	}
}
