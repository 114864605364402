import {
	CRM_SET_PRICE_GROUPS,
	CRM_SET_CURRENCIES,
	CRM_SET_LANGUAGES,
	CRM_SET_COUNTRIES,
} from '../action-types/crm';

export function setPriceGroups(priceGroups) {
	return {
		type: CRM_SET_PRICE_GROUPS,
		payload: { priceGroups },
	};
}

export function setCurrencies(currencies) {
	return {
		type: CRM_SET_CURRENCIES,
		payload: { currencies },
	};
}

export function setLanguages(languages) {
	return {
		type: CRM_SET_LANGUAGES,
		payload: { languages },
	};
}

export function setCountries(countries) {
	return {
		type: CRM_SET_COUNTRIES,
		payload: { countries },
	};
}
