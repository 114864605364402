import {
	ADD_MESSAGE,
	REMOVE_MESSAGE,
} from '../action-types/message';
import store from '../../store';

export function addMessage(message, err) {
	return {
		type: ADD_MESSAGE,
		payload: { message, err },
	};
}

export function removeMessage(id) {
	return {
		type: REMOVE_MESSAGE,
		payload: { id },
	};
}

export default function dispatchMessage(message, err) {
	store.dispatch(addMessage(message, err));
}
