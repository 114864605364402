import { SET_USER } from '../action-types/auth';

export function setUser(user) {
	return {
		type: SET_USER,
		payload: {
			user,
		},
	};
}
