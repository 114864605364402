import axios from 'axios';
import HttpStatus from 'http-status-codes';
import store from './store';
import { updateItems, updateCategories, updateProductGroups, clearCart, updateOrders } from './reducers/actions/webshop';
import { setPriceGroups, setCurrencies, setCountries, setLanguages } from './reducers/actions/crm';
import { setUser } from './reducers/actions/auth';
import dispatchMessage from './reducers/actions/message';
const url = '/api';

function getLoginUrl() {
	let path;
	if(!url.startsWith('http')) {
		path = window.location.origin;
		if(!url.startsWith('/')) {
			path += '/';
		}
		path += url;
	} else {
		path = url;
	}
	if(!path.endsWith('/')) {
		path += '/';
	}
	return `${path}auth/login?redirect=${encodeURIComponent(window.location)}`;
}

export function login(loginApi) {
	window.location = `${loginApi}?login=${encodeURIComponent(getLoginUrl())}`;
}

function clearState() {
	store.dispatch({ type: 'reset' });
}

function checkStatus(err) {
	if(err.response) {
		const { status } = err.response;
		if(status && (status === HttpStatus.FORBIDDEN || status === HttpStatus.UNAUTHORIZED)) {
			clearState();
			console.log('Clearing state');
		}
	}
	return Promise.reject(err);
}

function pushError(err) {
	dispatchMessage('An error occurred while trying to reach the server', err);
}

function get(path, params) {
	return axios.get(path, params).then(({ data }) => data).catch(checkStatus);
}

function post(path, params) {
	return axios.post(path, params).catch(checkStatus);
}

function put(path, params) {
	return axios.put(path, params).catch(checkStatus);
}

function del(path) {
	return axios.delete(path).catch(checkStatus);
}

function fetch(path, update, err = pushError, params) {
	get(`${url}/${path}`, params).then(data => {
		store.dispatch(update(data));
	}).catch(err);
}

export function fetchItems(err) {
	fetch('items', updateItems, err);
}

export function fetchCategories(err) {
	fetch('categories', updateCategories, err);
}

export function fetchProductGroups(err) {
	fetch('productgroups', updateProductGroups, err);
}

export function fetchPriceGroups(err) {
	fetch('pricegroups', setPriceGroups, err);
}

export function fetchCurrencies(err) {
	fetch('currencies', setCurrencies, err);
}

export function fetchCountries(err) {
	fetch('countries', setCountries, err);
}

export function fetchLanguages(err) {
	fetch('languages', setLanguages, err);
}

export function getAllOrders(invoices = false, err) {
	fetch('staff/orders', updateOrders, err, { params: { invoices } });
}

export function checkLoggedIn() {
	return get(`${url}/auth/me`).then(user => {
		store.dispatch(setUser(user));
	}).catch(err => {
		if(err.response && err.response.data) {
			return err.response.data.login;
		}
	});
}

export function logout(err = pushError) {
	post(`${url}/auth/logout`).then(() => {
		clearState();
		store.dispatch(clearCart());
	}).catch(err);
}

export function sendOrder({ order, address, customer, reference, aanbetaling, delivery, type }) {
	const args = { order, address, reference, aanbetaling, delivery: delivery.format(), type };
	if(customer) {
		args.customer = customer.klantNummer;
	}
	return post(`${url}/order`, args).then(({ data }) => data);
}

export function getOrder(id) {
	return get(`${url}/staff/orders/${encodeURIComponent(id)}`);
}

export function getUnsentOrder(id) {
	return get(`${url}/staff/orders/unsent/${encodeURIComponent(id)}`);
}

export function getItemTemplates() {
	return get(`${url}/item/template`);
}

export function getItemTemplate(id) {
	return get(`${url}/item/template/${encodeURIComponent(id)}`);
}

export function saveItemTemplate(template) {
	return post(`${url}/item/template`, template).then(res => res.data.id);
}

export function deleteItemTemplate(id) {
	return del(`${url}/item/template/${encodeURIComponent(id)}`);
}

export function instantiateItem(id, quantity) {
	return post(`${url}/item/template/${encodeURIComponent(id)}`, { quantity });
}

export function getCustomers(page, query) {
	return get(`${url}/staff/customers`, { params: { page, query } });
}

export function getCustomer(id) {
	return get(`${url}/staff/customers/${encodeURIComponent(id)}`);
}

export function checkVat(id) {
	return get(`${url}/staff/checkVat/${encodeURIComponent(id)}`);
}

export function getMostSoldItems() {
	return get(`${url}/staff/stats/items`);
}

export function saveAddress(customer, address) {
	return post(`${url}/staff/customers/${customer}/address`, address).then(res => res.data.id);
}

export function updateOrder({ _id }, address) {
	return post(`${url}/orders/${encodeURIComponent(_id)}`, { address }).then(res => res.data.id);
}

export function findCustomer(query) {
	return get(`${url}/staff/customers/find?q=${encodeURIComponent(query)}`);
}

export function createInvoice({ _id }) {
	return post(`${url}/staff/orders/${encodeURIComponent(_id)}/invoice`).then(res => res.data.id);
}

export function saveOrder({ _id }, data) {
	return post(`${url}/staff/orders/${encodeURIComponent(_id)}`, data);
}

export function removeShipping({ _id }) {
	return del(`${url}/staff/orders/${encodeURIComponent(_id)}/shipping`);
}

export function saveCurrency(currency) {
	return post(`${url}/staff/currencies`, currency).then(res => res.data.id);
}

export function getCurrencies() {
	return get(`${url}/currencies`);
}

export function getCurrency(id) {
	return get(`${url}/currencies/${encodeURIComponent(id)}`);
}

export function deleteCurrency(id) {
	return del(`${url}/staff/currencies/${encodeURIComponent(id)}`);
}

export function getShippingCosts({ order, address, customer, type }) {
	const args = { order, address, type };
	if(customer) {
		args.customer = customer.klantNummer;
	}
	return post(`${url}/order/shipping`, args).then(({ data }) => data);
}

export function updateLocations(item, updates) {
	return post(`${url}/staff/items/${encodeURIComponent(item)}`, updates);
}

export function getUnsentOrders(ids) {
	return get(`${url}/staff/orders/unsent`, { params: { ids } });
}

export function createOrderExport(orders) {
	return post(`${url}/staff/orders/unsent/csv`, orders).then(({ data }) => data);
}

export function uploadOrderExport(orders) {
	return post(`${url}/staff/orders/unsent/ftp`, orders);
}

export function getPickOrders() {
	return get(`${url}/staff/orders/pick`);
}

export function saveCollis(order, collis) {
	return post(`${url}/staff/orders/pick/${encodeURIComponent(order)}`, collis);
}

export function updateItem(item, updates) {
	return put(`${url}/staff/items/${encodeURIComponent(item)}`, updates);
}

export function getItems() {
	return get(`${url}/staff/items`);
}

export function sendInvoice(order) {
	return post(`${url}/staff/orders/${encodeURIComponent(order)}/mailInvoice`);
}

export function getBestSellers(from, to, limit) {
	return get(`${url}/staff/stats/items/time`, { params: { from, to, limit } });
}

export function createNavInvoice(order) {
	return post(`${url}/staff/orders/${encodeURIComponent(order)}/navinvoice`);
}

export function createNavCreditNote(order, note) {
	return post(`${url}/staff/orders/${encodeURIComponent(order)}/navcredit/${encodeURIComponent(note)}`);
}

export function createCreditNote(order, note) {
	return post(`${url}/staff/orders/${encodeURIComponent(order)}/credit`, note);
}

export function setOrderLoaded(order, ingeladen) {
	return post(`${url}/staff/orders/unsent/${encodeURIComponent(order)}`, { ingeladen });
}

export function deleteItems(item, reason, location, quantity, batch) {
	return post(`${url}/staff/items/${encodeURIComponent(item)}/delete`, {
		reason, location, quantity, batch,
	});
}

export function getReasons() {
	return get(`${url}/staff/deleteReasons`);
}
