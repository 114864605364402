import React from 'react';
import { Table, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import Pagination from '../../components/Paginator';
import queryString from 'query-string';
import { getCustomers } from '../../api';
import dispatchMessage from '../../reducers/actions/message';

const DELAY = 500;

class Customers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			customers: [],
			pages: 0,
			search: props.query || '',
		};
		this.search = this.search.bind(this);
		this.interval = 0;
		this.getUrl = this.getUrl.bind(this);
	}

	componentDidMount() {
		this.componentDidUpdate({});
	}

	componentDidUpdate(props) {
		if(props.page !== this.props.page || props.query !== this.props.query) {
			clearTimeout(this.interval);
			getCustomers(this.props.page, this.props.query).then(state => {
				this.setState(state);
			}).catch(err => {
				dispatchMessage('Failed to get customers', err);
			});
		}
	}

	getUrl(page) {
		if(page > 1) {
			return `/staff/customers?page=${page}&query=${encodeURIComponent(this.props.query)}`;
		}
		return `/staff/customers?query=${encodeURIComponent(this.props.query)}`;
	}

	search(e) {
		this.setState({ search: e.target.value });
		clearTimeout(this.interval);
		this.interval = setTimeout(() => {
			this.props.history.push(`/staff/customers?query=${encodeURIComponent(this.state.search)}`);
		}, DELAY);
	}

	render() {
		const { customers, pages, search } = this.state;
		return <section className="container">
			<h2>Klanten</h2>
			<Input className="mb-2" type="search" placeholder="Search" value={search} onChange={this.search} />
			<Table>
				<thead>
					<tr>
						<th>Nummer</th>
						<th>Naam</th>
					</tr>
				</thead>
				<tbody>
					{customers.map(customer =>
						<tr key={customer.klantNummer}>
							<td><Link to={`/staff/customers/${customer.klantNummer}`}>{customer.navCode}</Link></td>
							<td>{customer.naam}</td>
						</tr>
					)}
				</tbody>
			</Table>
			<Pagination pages={pages} page={this.props.page} getUrl={this.getUrl} />
		</section>;
	}
}

export default function Page(props) {
	const parsed = queryString.parse(props.location.search);
	let { page = 1 } = parsed;
	if(!Number.isInteger(+page) || page < 1) {
		page = 1;
	}
	return <Customers {...props} page={+page} query={parsed.query || ''} />;
}
