import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { getCurrency, deleteCurrency } from '../../api';
import dispatchMessage from '../../reducers/actions/message';

export default class Currency extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currency: null,
			redirect: false,
		};
		this.updateCurrency(props);
		this.deleteCurrency = this.deleteCurrency.bind(this);
	}

	componentWillReceiveProps(props) {
		this.updateCurrency(props);
	}

	updateCurrency(props) {
		const { id } = props.match.params;
		if(!this.state.currency || id !== this.state.currency._id) {
			getCurrency(id).then(currency => {
				this.setState({ currency });
			}).catch(err => {
				dispatchMessage('Failed to get currency', err);
			});
		}
	}

	deleteCurrency() {
		if(window.confirm('Weet u zeker dat u deze valuta wilt verwijderen?')) {
			deleteCurrency(this.state.currency._id).then(() => {
				this.setState({ redirect: true });
			}).catch(err => {
				dispatchMessage('Failed to delete currency', err);
			});
		}
	}

	render() {
		const { currency, redirect } = this.state;
		if(redirect) {
			return <Redirect to="/staff/currencies" push={true} />;
		} else if(!currency) {
			return null;
		}
		return <section className="container">
			<h1>{currency._id}</h1>
			<dl className="row">
				<dt className="col-md-4">Factor</dt>
				<dd className="col-md-8">{currency.factor}</dd>
			</dl>
			<p><Link to={`/staff/currencies/${currency._id}/edit`} className="ptmd-button">Edit</Link></p>
			<p><button className="ptmd-button red-button" onClick={this.deleteCurrency}>Delete</button></p>
		</section>;
	}
}
