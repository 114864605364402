import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Navigation from './Navigation';
import CartDropdown from './CartDropdown';
import FontAwesome from '../FontAwesome';
import withCart from '../../reducers/cart';
import { canSeeStaff } from '../../containers/staff/Staff';
const PERCENT = 100;

class TopBar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isNavOpen: false,
		};
		this.toggleNav = this.toggleNav.bind(this);
	}

	toggleNav() {
		this.setState(prevState => {
			return {
				isNavOpen: !prevState.isNavOpen,
			};
		});
	}

	render() {
		const { cart, i18n, user, shipping, customer, address, vat, weeks, setCustomer, getPriceAndCbm, type, setType } = this.props;
		const { isNavOpen } = this.state;
		const [totalPrice, cartTotal] = cart.reduce(([total, count], line) => {
			return [total + line.item.price * line.count * (1 - line.discount / PERCENT), count + line.count];
		}, [0, 0]);
		const isCustomer = user && user.roles.includes('CUSTOMER');
		const showCart = isCustomer || user && user.roles.includes('SALESPERSON');
		return (
			<div id="top-bar">
				<Row>
					<Col sm={2} className="left">
						<div className="ptmd-logo">
							<a href="#">
								<img src="images/QX logo.png" />
							</a>
						</div>
					</Col>

					<Col sm={8} className="middle">
						{showCart && <Row className="d-none d-md-flex">
							<Col sm={5}>
								<Link to="/checkout/customer" className="d-block h-100">
									{customer || isCustomer && address.adres ? <Fragment>
										<button className="btn btn-sm btn-danger float-right" onClick={e => {
											e.preventDefault();
											setCustomer(null);
										}}>
											<FontAwesome icon="trash" />
										</button>
										{!isCustomer && <Fragment>{customer.naam}<br /></Fragment>}
										{address.adres}
									</Fragment> : isCustomer ? 'Selecteer adres' : 'Selecteer klant'}
								</Link>
							</Col>
							<Col sm={4}>
								<dl className="row">
									<dt className="text-left col-sm-6">Totaal</dt>
									<dd className="text-right col-sm-6">{i18n.format(totalPrice)}</dd>
									<dt className="text-left col-sm-6">Transport</dt>
									<dd className="text-right col-sm-6">{isNaN(shipping) ? shipping : i18n.format(shipping)}</dd>
									<dt className="text-left col-sm-6">BTW</dt>
									<dd className="text-right col-sm-6">{vat}%</dd>
								</dl>
							</Col>
							<Col sm={3} className="text-center">
								{weeks && <img alt={weeks} src={`/images/uvf_week_${weeks}.png`.toLowerCase()} className="weeks" />}
							</Col>
						</Row>}
						<Navigation
							isOpen={isNavOpen}
							user={user}
						/>
					</Col>

					<Col sm={2} className="right">
						<div className="upper">
							<div className="nav-opener-container position-absolute right-icon">
								{showCart && <Link
									to="/checkout"
									className="shopping-cart-icon right-icon d-lg-none">
									<FontAwesome icon="shopping-cart" />
									{cartTotal > 0 && <span className="cart-badge">{cartTotal}</span>}
								</Link>}
								{user && canSeeStaff(user.roles) ?
									<Link className="d-inline-block d-lg-none" to="/staff">
										<FontAwesome icon="bars" className="nav-opener" />
									</Link> : <span onClick={this.toggleNav} className="d-inline-block d-lg-none">
										<FontAwesome icon="bars" className="nav-opener" />
									</span>}
							</div>
							{showCart && <Link
								to="/checkout"
								className="shopping-cart-icon right-icon d-none d-lg-block">
								<FontAwesome icon="shopping-cart" />
								{cartTotal > 0 && <span className="cart-badge">{cartTotal}</span>}
							</Link>}
							{user && <div className="logout-icon right-icon d-none d-lg-block">
								<Link to="/logout" title="Logout">
									<span className="name">{user.naam}</span> <FontAwesome icon="sign-out-alt" />
								</Link>
							</div>}
						</div>
					</Col>
				</Row>
				<CartDropdown
					cart={cart}
					i18n={i18n}
					user={user}
					type={type}
					setType={setType}
					getPriceAndCbm={getPriceAndCbm}
				/>
			</div>
		);
	}
}

export default withCart(TopBar);
