import {
	ADD_MESSAGE,
	REMOVE_MESSAGE,
} from './action-types/message';

let msgId = 0;

export default function reducer(state = [], action) {
	if(action.type === ADD_MESSAGE) {
		const { message, err } = action.payload;
		return state.concat({
			id: ++msgId,
			message,
			err,
		});
	} else if(action.type === REMOVE_MESSAGE) {
		const { id } = action.payload;
		return state.filter(msg => msg.id !== id);
	}
	return state;
}
