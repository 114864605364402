import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Table } from 'reactstrap';
import { getMostSoldItems, fetchItems } from '../../api';
import dispatchMessage from '../../reducers/actions/message';
import Items from './Items';

class InkoopContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mostSold: [],
		};
	}

	componentDidMount() {
		fetchItems();
		if(this.props.user.roles.includes('SALES')) {
			getMostSoldItems().then(mostSold => {
				const sold = mostSold.map(line => {
					return {
						count: line.count,
						artikelNummer: line._id,
					};
				});
				this.setState({ mostSold: sold });
			}).catch(err => {
				dispatchMessage('Failed to get most sold items', err);
			});
		}
	}

	render() {
		const { mostSold } = this.state;
		const { i18n, items, user } = this.props;
		const isSales = user.roles.includes('SALES');
		const isPurchase = user.roles.includes('PURCHASE');
		return <Row>
			<Col lg={6}>
				{isPurchase && <a href="/api/staff/stats/items/sold" className="ptmd-button">Exporteer verkochte artikelen (CSV)</a>}
				{isSales && Array.isArray(items) && <Fragment>
					<h2>Meest verkochte artikelen</h2>
					<Table>
						<thead>
							<tr><th colSpan={2}>Code</th><th>Naam</th><th>Aantal verkocht</th></tr>
						</thead>
						<tbody>
							{mostSold.map(line => {
								const item = items.find(({ artikelNummer }) => artikelNummer === line.artikelNummer);
								return <tr key={line.artikelNummer}>
									<td><img className="item-image" src={`https://api.ptmd.nl/rest/images/v1/small/${line.artikelNummer}.jpg`} /></td>
									<td>{line.artikelNummer}</td>
									<td>{item && i18n.select(item.naam)}</td>
									<td>{line.count}</td>
								</tr>;
							})}
						</tbody>
					</Table>
				</Fragment>}
			</Col>
			{isSales && <Col lg={6}>
				<h2>Voorraad</h2>
				<Items i18n={i18n} user={user} />
			</Col>}
		</Row>;
	}
}

export default connect(store => {
	const { items } = store.webshopState;
	return { items };
})(InkoopContainer);
