import React from 'react';
import { Table, Form } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { getUnsentOrder, saveCollis } from '../../api';
import dispatchMessage from '../../reducers/actions/message';

class PickOrder extends React.Component {
	constructor(props) {
		super(props);
		this.state = this.getState(props);
		this.state.redirect = false;
		this.saveColli = this.saveColli.bind(this);
	}

	componentDidUpdate(props) {
		if(this.props.order !== props.order) {
			this.setState(this.getState());
		}
	}

	getState(props = this.props) {
		const lines = props.order.regels.map(line => {
			return 'colli' in line ? line.colli : line.quantity;
		});
		const boxes = props.order.boxes || {};
		return {
			lines,
			smallBox: boxes.small || 0,
			largeBox: boxes.large || 0,
		};
	}

	change(index) {
		return e => {
			const { value } = e.target;
			this.setState({
				lines: this.state.lines.map((line, i) => {
					if(i === index) {
						return this.getValue(value);
					}
					return line;
				}),
			});
		};
	}

	getValue(value) {
		if(!isNaN(value) && value !== '') {
			return Number.parseInt(value);
		}
		return value;
	}

	saveColli(e) {
		e.preventDefault();
		const updates = {};
		const { order } = this.props;
		this.state.lines.forEach((line, i) => {
			if(line === '' || Number.isInteger(line)) {
				updates[order.regels[i].code] = line || 0;
			}
		});
		const { smallBox, largeBox } = this.state;
		const boxes = {
			small: Number.parseInt(smallBox) || 0,
			large: Number.parseInt(largeBox) || 0,
		};
		saveCollis(order._id, { updates, boxes }).then(() => {
			this.setState({ redirect: true });
		}).catch(err => {
			dispatchMessage('Failed to save collis', err);
		});
	}

	render() {
		const { order } = this.props;
		const { lines, redirect, smallBox, largeBox } = this.state;
		if(redirect) {
			return <Redirect to="/staff/pick" />;
		}
		return <section className="container">
			<h1>{order._id}</h1>
			<Form onSubmit={this.saveColli}>
				<Table>
					<thead>
						<tr>
							<th>Item</th>
							<th>Hoeveelheid</th>
							<th>Bezorgen</th>
							<th>Colli</th>
						</tr>
					</thead>
					<tbody>
						{order.regels.map((line, i) => <tr key={i}>
							<td>
								<img src={`https://api.ptmd.nl/rest/images/v1/small/${line.code}.jpg`} className="item-image" /><br />
								{line.code}
							</td>
							<td>{line.quantity}</td>
							<td><input type="checkbox" disabled checked={line.deliver} /></td>
							<td><input type="number" className="form-control" value={lines[i]} onChange={this.change(i)} /></td>
						</tr>)}
						<tr>
							<td colSpan={3}>Verhuisdoos</td>
							<td><input type="number" className="form-control" value={smallBox} onChange={e => {
								this.setState({ smallBox: this.getValue(e.target.value) });
							}} /></td>
						</tr>
						<tr>
							<td colSpan={3}>Pallet box</td>
							<td><input type="number" className="form-control" value={largeBox} onChange={e => {
								this.setState({ largeBox: this.getValue(e.target.value) });
							}} /></td>
						</tr>
					</tbody>
				</Table>
				<input type="submit" className="ptmd-button green-button" value="Save" />
			</Form>
		</section>;
	}
}

export default class Page extends React.Component {
	constructor(props) {
		super(props);
		this.state = { order: null };
	}

	componentDidMount() {
		getUnsentOrder(this.props.match.params.id).then(order => {
			this.setState({ order });
		}).catch(err => {
			dispatchMessage('Failed to get order', err);
		});
	}

	render() {
		const { order } = this.state;
		if(!order) {
			return null;
		}
		return <PickOrder order={order} />;
	}
}
