import React, { Fragment } from 'react';
import { Col, Row, Table, Form, Input, FormGroup } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getItemTemplate, instantiateItem } from '../../api';
import FontAwesome from '../../components/FontAwesome';
import dispatchMessage from '../../reducers/actions/message';

export default class ItemTemplate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			template: null,
			quantity: 1,
			created: null,
		};
		this.getTemplate(props);
		this.createInstances = this.createInstances.bind(this);
	}

	componentWillReceiveProps(props) {
		const { template } = props.match.params;
		if(template !== this.props.match.params.template) {
			this.getTemplate(props);
		}
	}

	getTemplate(props) {
		const { template: id } = props.match.params;
		getItemTemplate(id).then(template => {
			this.setState({ template });
		}).catch(err => {
			dispatchMessage('Failed to get item template', err);
		});
	}

	createInstances(e) {
		e.preventDefault();
		this.setState({ created: null });
		const { template, quantity } = this.state;
		instantiateItem(template._id, quantity).then(() => {
			this.setState({ quantity: 1, created: true });
		}).catch(err => {
			this.setState({ created: false });
			dispatchMessage('Failed to create instances', err);
		});
	}

	canInstantiate() {
		const { template } = this.state;
		if(template && template.artikelNummer && template.marketing && template.marketing.productGroep) {
			return true;
		}
		return false;
	}

	render() {
		const { template, quantity, created } = this.state;
		if(!template) {
			return <section>Loading</section>;
		}
		return <section className="container-fluid">
			<Row>
				<Col lg={6}>
					<h2>{template._id}</h2>
					<p><Link to={`/staff/template/${template._id}/edit`}><FontAwesome icon="edit" /> Bewerk</Link></p>
					<dl className="row">
						<dt className="col-lg-4">Artikelnummer</dt>
						<dd className="col-lg-8">{template.artikelNummer}</dd>
						<dt className="col-lg-4">EAN code</dt>
						<dd className="col-lg-8">{template.eanCode}</dd>
						{template.marketing && template.marketing.productGroep && <Fragment>
							<dt className="col-lg-4">Productgroep</dt>
							<dd className="col-lg-8">{template.marketing.productGroep}</dd>
						</Fragment>}
						{template.marketing && template.marketing.collectie && <Fragment>
							<dt className="col-lg-4">Collectie</dt>
							<dd className="col-lg-8">{template.marketing.collectie}</dd>
						</Fragment>}
						{template.locatie && <Fragment>
							<dt className="col-lg-4">Locatie</dt>
							<dd className="col-lg-8">{template.locatie}</dd>
						</Fragment>}
					</dl>
				</Col>
				<Col lg={6}>
					<Form className="form-inline" onSubmit={this.createInstances}>
						<FormGroup>
							<Input
								type="number"
								min={1}
								value={quantity}
								className={created ? 'is-valid' : created === false ? 'is-invalid' : ''}
								onChange={e => this.setState({ quantity: e.target.value })} />
						</FormGroup>
						<Input
							type="submit"
							className="btn btn-primary"
							value="Maak aan"
							disabled={!this.canInstantiate()} />
					</Form>
					<p>{template.artikelNummer && <img className="item-image" src={`https://api.ptmd.nl/rest/images/v1/small/${template.artikelNummer}.jpg`} />}</p>
				</Col>
			</Row>
			<Row>
				<Col lg={6}>
					<Table>
						<thead>
							<tr><th>Taal</th><th>Naam</th></tr>
						</thead>
						<tbody>
							{Object.keys(template.naam || {}).map(key =>
								<tr key={key}>
									<td>{key}</td>
									<td>{template.naam[key]}</td>
								</tr>
							)}
						</tbody>
					</Table>
					<Table>
						<thead>
							<tr><th>Taal</th><th>Omschrijving</th></tr>
						</thead>
						<tbody>
							{Object.keys(template.omschrijving || {}).map(key =>
								<tr key={key}>
									<td>{key}</td>
									<td>{template.omschrijving[key]}</td>
								</tr>
							)}
						</tbody>
					</Table>
					<Table>
						<thead>
							<tr><th>Soort</th><th>Prijs</th></tr>
						</thead>
						<tbody>
							{Object.keys(template.prijzen || {}).map(key =>
								<tr key={key}>
									<td>{key}</td>
									<td>{template.prijzen[key]}</td>
								</tr>
							)}
						</tbody>
					</Table>
				</Col>
				<Col lg={6}>
					<Table>
						<thead>
							<tr>
								<th>Afmeting</th>
								<th>Hoogte</th>
								<th>Breedte</th>
								<th>Lengte</th>
								<th>Gewicht</th>
								<th>CBM</th>
								<th>Verpakkings eenheid</th>
								<th>Barcode</th>
							</tr>
						</thead>
						<tbody>
							{['item', 'innerbox', 'outerbox'].filter(key => template.afmetingen && template.afmetingen[key]).map(key =>
								<tr key={key}>
									<th>{key}</th>
									<td>{template.afmetingen[key].height}</td>
									<td>{template.afmetingen[key].width}</td>
									<td>{template.afmetingen[key].length}</td>
									<td>{template.afmetingen[key].weight}</td>
									<td>{template.afmetingen[key].cbm}</td>
									<td>{template.afmetingen[key].verpakkingsEenheid}</td>
									<td>{template.afmetingen[key].barcode}</td>
								</tr>
							)}
						</tbody>
					</Table>
				</Col>
			</Row>
		</section>;
	}
}
