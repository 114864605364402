import React from 'react';
import { getOrder, getCustomer } from '../../api';
import Order from '../../components/orders/Order';
import CreditOrder from './CreditOrder';
import dispatchMessage from '../../reducers/actions/message';

export default class StaffOrderContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			order: null,
			customer: null,
		};
		this.updateOrder(props);
		this.forceUpdate = this.forceUpdate.bind(this);
	}

	componentWillReceiveProps(props) {
		this.updateOrder(props);
	}

	updateOrder(props) {
		const { order: o } = this.state;
		if(!o || props.match.params.order !== o._id || this.props.match.path !== props.match.path) {
			this.forceUpdate(props.match.params.order);
		}
	}

	forceUpdate(id = this.props.match.params.order) {
		let order;
		getOrder(id).then(o => {
			order = o;
			return getCustomer(order.klant.klantNummer).catch(err => {
				dispatchMessage('Failed to get customer', err);
				return null;
			});
		}).then(customer => {
			this.setState({ customer, order });
		}).catch(err => {
			dispatchMessage('Failed to get order', err);
		});
	}

	render() {
		const { order, customer } = this.state;
		if(!order) {
			return null;
		}
		const { i18n, user, credit } = this.props;
		const Comp = credit ? CreditOrder : Order;
		return <Comp order={order}
			user={user}
			i18n={i18n}
			customer={customer}
			update={this.forceUpdate} />;
	}
}
