import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import HttpStatus from 'http-status-codes';
import { sendOrder } from '../api';
import dispatchMessage from '../reducers/actions/message';
import withCart from '../reducers/cart';
import Step1 from './checkout/Step1';
import Step2 from './checkout/Step2';
import Step3 from './checkout/Step3';
const STEPS = 3;
const WEEKS = {
	B: 1,
	C: 2,
	D: 3,
};
const PERCENT = 100;
const AANBETALING = 0.25;

function percent(percentage) {
	return 1 + (isNaN(percentage) ? 0 : percentage) / PERCENT;
}

class Checkout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			placed: false,
			reference: '',
			step: 0,
			aanbetaling: '',
			delivery: moment(),
			startDate: moment().add(WEEKS[props.weeks] || 1, 'weeks').startOf('week'),
			extraWeeks: 0,
			price: null,
		};
		if(props.match && props.match.params.step) {
			this.state.step = Math.min(Math.max(parseInt(props.match.params.step || 1), 1), STEPS) - 1;
		}
		this.order = this.order.bind(this);
		this.getOrder = this.getOrder.bind(this);
	}

	componentDidUpdate(props, state) {
		if(this.props.match !== props.match) {
			this.setState({
				step: Math.min(Math.max(parseInt(this.props.match.params.step || 1), 1), STEPS) - 1,
			});
		}
		let { delivery, startDate } = this.state;
		if(props.weeks !== this.props.weeks) {
			startDate = moment().add(WEEKS[this.props.weeks] || 1, 'weeks').startOf('week');
			const update = { startDate };
			if(delivery.isBefore(startDate)) {
				update.delivery = startDate;
				delivery = startDate;
			}
			this.setState(update);
		}
		if(state.delivery !== this.state.delivery || this.props.cart !== props.cart || this.state.price === null && this.props.cart && this.props.cart.length) {
			const extraWeeks = delivery.diff(startDate, 'weeks');
			const { shipping, vat, getPriceAndCbm, surcharge } = this.props;
			const { totalPrice } = getPriceAndCbm();
			const price = (totalPrice + (isNaN(shipping) ? 0 : shipping)) * percent(vat) * percent(surcharge);
			this.setState({ extraWeeks, price });
			if(extraWeeks > 0) {
				const aanbetaling = price * AANBETALING;
				this.setState({ aanbetaling: Math.round(aanbetaling * PERCENT) / PERCENT });
			}
		}
	}

	getOrder() {
		const { reference, aanbetaling, delivery } = this.state;
		return this.props.getOrder({ reference, aanbetaling, delivery });
	}

	order(e) {
		e.preventDefault();
		sendOrder(this.getOrder()).then(({ id }) => {
			this.setState({ placed: id });
			this.props.clearCart();
			this.props.setCustomer(null);
		}).catch(err => {
			if(err.response && err.response.status === HttpStatus.PRECONDITION_FAILED) {
				return dispatchMessage(err.response.data.message, err);
			}
			dispatchMessage('Failed to place order', err);
		});
	}

	render() {
		const { cart, i18n, user, shipping, vat, weeks, getPriceAndCbm, type, setType } = this.props;
		const { placed, reference, step, aanbetaling, delivery, extraWeeks, startDate, price } = this.state;
		if(!cart.length && placed) {
			if(user.roles.includes('SALESPERSON')) {
				return <Redirect to={`/staff/orders/${placed}`} />;
			}
			return <section className="container">
				<h2>Uw order is geplaatst</h2>
			</section>;
		}
		const setState = state => this.setState(state);
		const steps = [
			<Step1 cart={cart} i18n={i18n} user={user} getPriceAndCbm={getPriceAndCbm}
				type={type} setType={setType} />,
			<Step2 cart={cart} i18n={i18n} user={user} delivery={delivery}
				startDate={startDate} shipping={shipping} btw={vat}
				setState={setState} weeks={weeks} extraWeeks={extraWeeks}
				getPriceAndCbm={getPriceAndCbm} type={type} />,
			<Step3 user={user} order={this.order} reference={reference} i18n={i18n}
				setState={setState} aanbetaling={aanbetaling} price={price || 0} />,
		];
		return <section className="container">
			{steps[step]}
		</section>;
	}
}

export default connect(s => {
	const { user } = s.auth;
	return { user };
})(withCart(Checkout));
