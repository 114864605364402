import React from 'react';
import { Link } from 'react-router-dom';
import Cart from '../../components/webshop/Cart';

export default class Step1 extends React.Component {
	render() {
		const { cart, i18n, user, getPriceAndCbm, setType, type } = this.props;
		return <Cart
			readOnly={false}
			cart={cart}
			i18n={i18n}
			user={user}
			type={type}
			setType={setType}
			getPriceAndCbm={getPriceAndCbm}>
			<Link to="/checkout/2" className="ptmd-button green-button">Volgende</Link>
		</Cart>;
	}
}
