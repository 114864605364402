import { CreateItemTemplate, mapToProps } from './CreateItemTemplate';
import { getItemTemplate } from '../../api';
import dispatchMessage from '../../reducers/actions/message';

class EditItemTemplate extends CreateItemTemplate {
	constructor(props) {
		super(props);
		this.getTemplate(props);
	}

	componentWillReceiveProps(props) {
		const { template } = props.match.params;
		if(template !== this.props.match.params.template) {
			this.getTemplate(props);
		}
	}

	getTemplate(props) {
		const { template: id } = props.match.params;
		getItemTemplate(id).then(template => {
			this.setState({ template });
		}).catch(err => {
			dispatchMessage('Failed to get item template', err);
		});
	}

	render() {
		const { template } = this.state;
		if(!Object.keys(template).length) {
			return null;
		}
		return super.render();
	}
}

export default mapToProps(EditItemTemplate);
