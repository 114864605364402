import {
	CRM_SET_PRICE_GROUPS,
	CRM_SET_CURRENCIES,
	CRM_SET_LANGUAGES,
	CRM_SET_COUNTRIES,
} from './action-types/crm';

const initialState = {
	priceGroups: [],
	currencies: [],
	languages: [],
	countries: [],
};

export default function reducer(state = initialState, action) {
	if(action.type === CRM_SET_CURRENCIES) {
		const { currencies } = action.payload;
		return Object.assign({}, state, { currencies });
	} else if(action.type === CRM_SET_PRICE_GROUPS) {
		const { priceGroups } = action.payload;
		return Object.assign({}, state, { priceGroups });
	} else if(action.type === CRM_SET_LANGUAGES) {
		const { languages } = action.payload;
		return Object.assign({}, state, { languages });
	} else if(action.type === CRM_SET_COUNTRIES) {
		const { countries } = action.payload;
		return Object.assign({}, state, { countries });
	} else if(action.type === 'reset') {
		return initialState;
	}
	return state;
}
